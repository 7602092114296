import React, { useRef, useEffect, useCallback, useMemo } from "react";
import { motion, useSpring, useTransform } from "framer-motion";
import normalizeWheel from "normalize-wheel";
import { useRafLoop } from "react-use";
import { useWindowSize } from "@react-hook/window-size";
import { throttle } from "lodash";

const MarqueeItem = React.memo((props) => {
  const { children, speed } = props;

  const itemRef = useRef(null);
  const rectRef = useRef(null);
  const x = useRef(0);
  const [width, height] = useWindowSize();

  const setX = useCallback(() => {
    if (!itemRef.current || !rectRef.current) {
      return;
    }

    const xPercentage = (x.current / rectRef.current.width) * 100;

    if (xPercentage < -100) {
      x.current = 0;
    }

    if (xPercentage > 0) {
      x.current = -rectRef.current.width;
    }

    itemRef.current.style.transform = `translate3d(${xPercentage}%, 0, 0)`;
  }, []);

  useEffect(() => {
    if (itemRef.current) {
      rectRef.current = itemRef.current.getBoundingClientRect();
    }
  }, [width, height]);

  const loop = useCallback(() => {
    x.current -= speed.get();
    setX();
  }, [speed, setX]);

  const [_, loopStart, loopStop] = useRafLoop(loop, false);

  useEffect(() => {
    loopStart();
    return () => loopStop();
  }, [loopStart, loopStop]);

  return (
    <motion.div className="item" ref={itemRef}>
      {children}
    </motion.div>
  );
});

const InteractiveMarquee = (props) => {
  const {
    speed = 1,
    threshold = 0.1,
    dragFactor = 0.5,
    children
  } = props;

  const marqueeRef = useRef(null);
  const slowDown = useRef(false);
  const isScrolling = useRef(null);
  const constraintsRef = useRef(null);
  const x = useRef(0);
  const [wWidth] = useWindowSize();

  const speedSpring = useSpring(speed, {
    damping: 40,
    stiffness: 90,
    mass: 5
  });

  const handleDragStart = useCallback(() => {
    slowDown.current = true;
    marqueeRef.current.classList.add("drag");
    speedSpring.set(0);
  }, [speedSpring]);

  const handleOnDrag = useCallback(
    (_, info) => {
      speedSpring.set(dragFactor * -info.delta.x);
    },
    [dragFactor, speedSpring]
  );

  const handleDragEnd = useCallback(() => {
    slowDown.current = false;
    marqueeRef.current.classList.remove("drag");
    x.current = speed;
  }, [speed]);

  const loop = useCallback(() => {
    if (slowDown.current || Math.abs(x.current) < threshold) {
      return;
    }

    x.current *= 0.66;

    if (x.current < 0) {
      x.current = Math.min(x.current, 0);
    } else {
      x.current = Math.max(x.current, 0);
    }

    speedSpring.set(speed + x.current);
  }, [speed, threshold, speedSpring]);

  const [_, loopStart, loopStop] = useRafLoop(loop);

  useEffect(() => {
    loopStart();
    return () => loopStop();
  }, [loopStart, loopStop]);

  return (
    <>
      <motion.div className="bg" ref={constraintsRef} />
      <motion.div
        className="marquee"
        ref={marqueeRef}
        drag="x"
        dragPropagation={true}
        dragConstraints={{ left: 0, right: 0 }}
        onDragStart={handleDragStart}
        onDrag={handleOnDrag}
        onDragEnd={handleDragEnd}
        dragElastic={0.000001}
      >
        <MarqueeItem speed={speedSpring}>{children}</MarqueeItem>
        <MarqueeItem speed={speedSpring}>{children}</MarqueeItem>
      </motion.div>
    </>
  );
};

export default InteractiveMarquee;
