const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  isMobileAction = (isMobile) => ({
    isMobile,
    type: "IS_MOBILE",
  }),
  mainMenuOpenAction = (mainMenuOpen) => ({
    mainMenuOpen,
    type: "MAIN_MENU_OPEN",
  }),
  accessibilityMenuOpenAction = (accessibilityMenuOpen) => ({
    accessibilityMenuOpen,
    type: "ACCESSIBILITY_MENU_OPEN",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  mainSettingsAction = (mainSettings) => ({
    mainSettings,
    type: "MAIN_SETTINGS",
  }),
  releaseStateAction = (releaseState) => ({
    releaseState,
    type: "RELEASE_STATE",
  }),
  menuTitleAction = (menuTitle) => ({
    menuTitle,
    type: "MENU_TITLE",
  }),
  hideSortAction = (hideSort) => ({
    hideSort,
    type: "HIDE_SORT",
  }),
  hideFilterAction = (hideFilter) => ({
    hideFilter,
    type: "HIDE_FILTER",
  }),
  searchOpenAction = (searchOpen) => ({
    searchOpen,
    type: "SEARCH_OPEN",
  }),
  microSiteAction = (microSite) => ({
    microSite,
    type: "MICROSITE",
  }),
  microSiteFrontPageAction = (microSiteFrontPage) => ({
    microSiteFrontPage,
    type: "MICROSITE_FRONTPAGE",
  }),
  persistViewSwitchAction = (persistViewSwitch) => ({
    persistViewSwitch,
    type: "PERSIST_VIEW_SWITCH",
  }),
  overwriteFooterAction = (overwriteFooter) => ({
    overwriteFooter,
    type: "OVERWRITE_FOOTER",
  }),
  customHistoryAction = (customHistory) => ({
    customHistory,
    type: "CUSTOM_HISTORY",
  }),
  panicScrollBlockAction = (panicScrollBlock) => ({
    panicScrollBlock,
    type: "PANIC_SCROLL_BLOCK",
  }),
  preventJumpToCurrentTimeAction = (preventJumpToCurrentTime) => ({
    preventJumpToCurrentTime,
    type: "PREVENT_JUMP_TO_CURRENT_TIME",
  });

export {
  adminAppAction,
  isMobileAction,
  mainMenuOpenAction,
  accessibilityMenuOpenAction,
  pagerFullPagesAction,
  pagerFullPageContextAction,
  mainSettingsAction,
  releaseStateAction,
  menuTitleAction,
  hideSortAction,
  hideFilterAction,
  searchOpenAction,
  microSiteAction,
  microSiteFrontPageAction,
  persistViewSwitchAction,
  overwriteFooterAction,
  customHistoryAction,
  panicScrollBlockAction,
  preventJumpToCurrentTimeAction
};
