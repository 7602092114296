import React from "react";
import NodeWrapper from "../shared-components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import newsPageQuery from "./news.graphql";
import ContentBase from "@general-components/content-base/content-base";
import FieldTime from "@general-components/date-time/field-time";
import Image from "../../general-components/image/image";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

const News = () => {

  const { entity, loading, error } = useNodeQuery(newsPageQuery);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity &&
        <div className="container news">
          <div className="row">
            <div className="col-16 col-md-8 image-col">
              {entity?.fieldImage &&
                <Image
                  data={entity.fieldImage.fieldMediaImage}
                  nodeTitle={entity.title}
                  credit={entity.fieldImage.fieldCredits}
                />
              }
            </div>
            <div className="col-16 col-md-7 offset-md-1 content-col">
              <div className="meta">
                {entity?.publishedAt &&
                  <div className="published-on">
                    <FieldTime
                      timestamp={false}
                      date={entity?.publishedAt}
                      format={"D MMMM"}
                    />
                  </div>
                }
                {entity.fieldSponsored &&
                  <span> Sponsored News</span>
                }
              </div>

              {entity.label &&
                <Hyphenated language={de}>
                  <h1>{entity.label}</h1>
                </Hyphenated>
              }

              {entity?.fieldModules && (
                <ContentBase
                  content={entity.fieldModules.list}
                  viewMode={"news"}
                />
              )}
            </div>
          </div>
        </div>
      }
    </NodeWrapper>
  );
};

News.propTypes = {};

export default News;
