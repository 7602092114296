const sortByDate = (arr) => {
  // Create a shallow copy of the array before sorting to avoid modifying the original array
  // Remove any falsy values from the array (e.g. null, undefined, empty strings)
  const arrCopy = [...(arr.filter(Boolean))];

  return arrCopy.sort((a, b) => {
    // Convert the "value" fields to numbers (they are in UNIX timestamp format)
    const dateA = parseInt(a.fieldDateRawField?.first.value, 10);
    const dateB = parseInt(b.fieldDateRawField?.first.value, 10);

    // Sort in ascending order
    return dateA - dateB;
  });
};

export { sortByDate };
