import { encodeUrl } from "../../../../../lib/encode-url";
import ErrorBoundary from "../../../../../error-boundary";
import React from "react";
import { useSelector } from "react-redux";
import TeaserScrollOverviewImage from "./teaser-scroll-overview-image";
import BreakBanner from "./break-banner";
import PropTypes from "prop-types";

const transformNodesToNodeImageLinks = ({ nodes, type, showBanners }) => {
  const mainSettings = useSelector(
    (reduxStore) => reduxStore.appStore.mainSettings
  );

  let preparedNodes = [],
    breaks = false;

  /* Check if there are breaks filled in main settings config and determine the type */
  if (type === "conference") {
    breaks = mainSettings?.fieldConferenceBreaks;
  } else {
    breaks = mainSettings?.fieldParticipantBreaks;
  }

  nodes &&
    nodes.map((item, index) => {
      preparedNodes.push(
        <a
          href={`#${encodeUrl(`${item.title}-${item.id}`)}`}
          key={index}
          className={"scrollspy-anchor"}
          aria-hidden="true"
          tabIndex="-1"
          aria-label={`link: ${item.title}`}
        >
          {(() => {
            return (
              <ErrorBoundary>
                <title className={""}>{item.title}</title>
                <TeaserScrollOverviewImage item={item} index={index} />
              </ErrorBoundary>
            );
          })()}
        </a>
      );
      // Push Breaks every 10th element
      if (showBanners && breaks && breaks[(index + 1) / 10 - 1]) {
        preparedNodes.push(
          <a
            href={`${encodeUrl(
              breaks[(index + 1) / 10 - 1].entity.fieldHeading
            )}-${index}`}
            key={index}
          >
            {breaks && breaks[(index + 1) / 10 - 1] && (
              <BreakBanner
                content={breaks[(index + 1) / 10 - 1].entity}
                id={index}
                type={type}
              />
            )}
          </a>
        );
      }
    });

  return preparedNodes;
};

transformNodesToNodeImageLinks.propTypes = {
  nodes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  showBanners: PropTypes.bool.isRequired,
};

export default transformNodesToNodeImageLinks;
