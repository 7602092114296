import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import RBXLogo from "@/assets/rbx-company/RBX-Logo-white.svg";

const Error500 = ({ error }) => {
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  return (
    <div className="error-500">
      <div className="container">
        <div className="row">
          <main className="col-16">
            {microSite ? (
              <img
                src={RBXLogo}
                className="rbx-logo"
                alt={"Logo RBX"}
              />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27" viewBox="0 0 34 27" fill="none">
                <path
                  d="M13.7345 2.76392L0 6.02167L1.68812 13.0622L4.00775 12.5129L4.01047 12.5277L4.0159 12.5264L7.28892 26.1728L18.6997 23.4684L13.7345 2.76392Z"
                  fill="black"/>
                <path
                  d="M32.2508 12.8692L27.8886 10.7072L27.856 10.6924L16.6992 13.3415L18.1741 19.4319L23.8089 22.2808L34 19.857L32.2508 12.8692Z"
                  fill="black"/>
                <path
                  d="M21.9009 0.827393L14.1543 2.6641L16.6247 12.9191L24.6388 11.0163L27.7475 7.71538L26.4967 2.49676L21.9009 0.827393Z"
                  fill="black"/>
              </svg>
            )}

            <div className="text-wrapper">
              <h1 className="text">
                Wir sind bald wieder da! Unsere Website ist derzeit wegen Wartungsarbeiten nicht erreichbar.
              </h1>
              <p className="text emojis">
                {"(>_<) ⊙︿⊙ (｡•́︿•̀｡)"}
              </p>

              <p className="text">
                We will be back soon! Our website is currently unavailable due to maintenance work.
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

Error500.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Error500;
