import React from "react";
import PropTypes from "prop-types";

import ContentBase from "../../../../content-base";
import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";

const CloudDetailOverlay = ({ content }) => {
  return (
    <div className="cloud-detail-overlay">
      <div className="cloud-detail-inner">
        <div className="cloud-detail-content">
          {content.fieldBild && (
            <img
              src={content.fieldBild.entity.fieldMediaImage.style.url}
              alt={content.fieldBild.entity.fieldMediaImage?.alt}
            />
          )}
          {content.entityLabel && (
            <div className="title">
              <h2>{content.entityLabel}</h2>
            </div>
          )}

          {content.fieldTeasertext && content.fieldTeasertext.processed && (
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.fieldTeasertext.processed,
              }}
            />
          )}

          {content.fieldModules && (
            <ContentBase content={content.fieldModules} />
          )}
        </div>
      </div>
    </div>
  );
};

CloudDetailOverlay.propTypes = {
  content: PropTypes.shape(ContentCloudNodePropTypes),
};

export default CloudDetailOverlay;
