import React from "react";
import { FormattedMessage } from "react-intl";
import Image from "../../general-components/image/image";
import LazyLoad from "react-lazyload";

const Sponsors = ({ content }) => {
  let sponsors = [''];

  if (content.fieldCoopCompanyRawField?.list) {
    sponsors = [...content.fieldCoopCompanyRawField?.list];
  }

  if (content.fieldAppearances?.length) {
    content.fieldAppearances.forEach((appearance, i) => {
      if (appearance.fieldMainPresenterRawField?.list)
        sponsors = [...sponsors, ...appearance.fieldMainPresenterRawField.list];
    })
  }

  if (content.fieldPresenterRawField?.list) {
    sponsors = [...sponsors, ...content.fieldPresenterRawField.list];
  }

  // Remove duplicates based on sponsor.targetId
  sponsors = sponsors.reduce((acc, current) => {
    const x = acc.find(item => item.targetId === current.targetId);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  sponsors = sponsors.filter((e) => { return e });

  if (sponsors && sponsors.length > 0 && sponsors !== [""]) {
    return (
      <div className="sponsor-wrapper">
        <div className="all-sponsors">
          <div className="row">
            <div className="label body-m col-16">
              <FormattedMessage id="cooperation" />
            </div>
            {sponsors && sponsors.length > 0 && sponsors.map((sponsor, index) =>
              <>
                {sponsor?.entity?.fieldImage &&
                  <div className="sponsor main col" key={index}>
                    <div className={`sponsor-image`}>
                      <a href={sponsor.entity?.fieldWebsite?.uri?.path} target="_blank">
                        {sponsor.entity?.fieldImage &&
                          <>
                            <span className="sr-only">{sponsor.entity.title}</span>
                            {sponsor.entity.fieldImage &&
                              <LazyLoad>
                                <Image
                                  data={sponsor.entity.fieldImage.fieldMediaImage}
                                  // credit={sponsor.entity.fieldImage.fieldCredits}
                                  nodeTitle={sponsor.entity.title}
                                />
                              </LazyLoad>
                            }
                          </>
                        }
                      </a>
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export default Sponsors;
