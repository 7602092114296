import React  from "react";
import PropTypes from "prop-types";

import Image from "../../../../image/image";
import { encodeUrl } from "../../../../../lib/encode-url";
import ProgressiveImage from "react-progressive-graceful-image";

const TeaserScrollOverviewImage = ({item, index}) => {

  return (
    <article
      className="participant-image"
      data-to-scrollspy-id={encodeUrl(`${item.title}-${item.id}`)}
    >
      {item.fieldImage?.fieldMediaImage &&
        <ProgressiveImage
          src={item.fieldImage.fieldMediaImage.style.url}
          placeholder={item.fieldImage.fieldMediaImage.thumbnail?.url}
          delay={0}
        >
          {(src, loading) => (
            <Image
              data={item.fieldImage.fieldMediaImage}
              credit={item.fieldImage.fieldCredits}
              loading={loading}
              src={src}
              nodeTitle={item.title}
            />
          )}
        </ProgressiveImage>
      }
    </article>
  );
}

TeaserScrollOverviewImage.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default TeaserScrollOverviewImage;