import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";
import {FormattedMessage} from "react-intl";
import Appearances from "@nodes/shared-components/appearances/appearances";
import moment from "moment";

const selectCorrectAppearanceOnShowcase = (fullItem, showcaseEvent) => {
  const showcaseEventDate = showcaseEvent.fieldAppearances[0]?.fieldDateRawField.first;
  let alterableItem = structuredClone(fullItem);

  // Check if The Showcase appearance is inside of the Appearances start- and enddate, scince Piet is only giving us a reference to the Act, not the Appearance.
  alterableItem.fieldAppearances = fullItem.fieldAppearances.filter((appearance) => {
    return (
        moment.unix(appearance?.fieldDateRawField?.first?.value).isBetween(moment.unix(showcaseEventDate.value).subtract(5, 'minutes'), moment.unix(showcaseEventDate.endValue).add(5, 'minutes'), 'minute')
      );
    }
  );

  return alterableItem;
}

const TeaserActShowcase = ({ item, index, showcaseEvent }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const dispatch = useDispatch(),
    location = useLocation();

  const showcaseAct = selectCorrectAppearanceOnShowcase(item, showcaseEvent);

  return (
    <article
      className="node node-teaser teaser-act showcase masked col-16 col-md-8"
      data-index={index}
    >
      <div className="row">
        <div className="mask-wrapper col-8">
          <EditButton
            adminApp={adminApp}
            entityId={showcaseAct.id}
            destinationRoute={location.pathname}
          />
          {showcaseAct.fieldImage && (
            <Image
              data={showcaseAct.fieldImage.fieldMediaImage}
              nodeTitle={showcaseAct.title}
            />
          )}
        </div>
        <div className="infos col-8">
          <Appearances
            detailedMode={false}
            content={showcaseAct}
            type={"act"}
            showEndDate={true}
            forceLongDayName={false}
            showAddToCalendar={false}
          />
          <h3 className="headline-s">
            {showcaseAct.label}
          </h3>
          <Link
            //onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
            className="action-element body-m"
            to={showcaseAct.url?.path || showcaseAct.path.alias}
          >
            <FormattedMessage id="learn_more" />
          </Link>
        </div>
      </div>
    </article>
  );
};

export const TeaserActShowcasePropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserActShowcase.propTypes = {
  item: TeaserActShowcasePropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserActShowcase;
