import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import LoadingIndicator from "../../../loading-indicator";

const ParagraphNewsletterRegistration = ({ content }) => {
  const intl = useIntl();

  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitHandler = (values, actions) => {
    setLoading(true);

    const channel = content.fieldNewsletterChannel;

    axios.post(
        channel.fieldApiEndpoint,
        {
          email: values.email,
          privacy_policy_accepted: true,
          _unsubscribed: false,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${channel.fieldApiToken}`,
          },
        }
      )
      .catch((error) => {
        setError(true);
        setErrorStatus(error.response.status);
        setLoading(false);
        setSuccess(false);

        actions.setSubmitting(false);
      })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          setError(false);
          setErrorStatus(false);
          setLoading(false);
          setSuccess(true);

          actions.setSubmitting(false);
          actions.resetForm();
        } else {
          setError(true);
          setErrorStatus(406);
          setLoading(false);
          setSuccess(false);

          actions.setSubmitting(false);
        }
      });
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-newsletter-registration": true,
    [`color-${content.fieldColorScheme || 'basic'}`]: true,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      {loading && <LoadingIndicator />}

      <div className="container">
        <div className="row">
          {success ? (
            <div className="col-16 heading">
              {content.fieldHeading && (
                <h2>
                  <FormattedMessage id="newsletter.success" />
                </h2>
              )}
            </div>
          ) : (
            <>
              <div className="col-16 heading">
                {content.fieldHeading && (
                  <h2>{content.fieldHeading}</h2>
                )}
              </div>
              <div className="col-16 form-part">
                <Formik
                  onSubmit={submitHandler}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    email: "",
                    consent: false,
                  }}
                  validationSchema={Yup.lazy(() =>
                    Yup.object().shape({
                      email: Yup.string()
                        .email()
                        .required(
                          intl.formatMessage({ id: "newsletter.form.email_error" })
                        ),
                      consent: Yup.bool().oneOf(
                        [true],
                        intl.formatMessage({ id: "newsletter.form.consent_error" })
                      ),
                    })
                  )}
                >
                  {({ errors, touched }) => (
                    <Form noValidate>
                      <div className="form-row">
                        <div className="col-16 form-group">
                          <label htmlFor="email">
                            <FormattedMessage id="newsletter.form.email_label" />
                          </label>
                          <div className="input-wrapper">
                            <Field
                              aria-label={intl.formatMessage({
                                id: "newsletter.form.email_label",
                              })}
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="E-Mail"
                            />
                            <button type="submit" className="submit-newsletter">
                              <FormattedMessage id="newsletter.form.submit" />
                            </button>
                          </div>
                          {error &&
                            <div
                              className="form-error text-danger"
                              role="region"
                              aria-live="polite"
                            >
                              :-/ <FormattedMessage id="newsletter.duplicate" />
                            </div>
                          }
                        </div>

                        <div className="col-16 form-check">
                          <Field
                            type="checkbox"
                            name="consent"
                            id="consent"
                            aria-labelledby="form-check-label"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="consent">
                            <FormattedMessage
                              id="newsletter.form.consent_label"
                              values={{ privacyPolicyUrl: "aaabasdb" }}
                            />
                            {errors.consent && touched.consent ? (
                              <span
                                className="form-error text-danger"
                                role="region"
                                aria-live="polite"
                              >
                            {errors.consent}
                          </span>
                            ) : null}
                          </label>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphNewsletterRegistration.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldText: PropTypes.string,
  }),
};

export default ParagraphNewsletterRegistration;
