import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../../../image/image";
import EditButton from "../../../../../backend/edit-button";
import LazyLoad from "react-lazyload";
import { encodeUrl } from "../../../../../lib/encode-url";
import CustomHyphenation from "@general-components/custom-hyphenation";
import Link from "@general-components/link/link";

const Content = ({ item }) => {
  const mainSettings = useSelector((reduxStore) => reduxStore.appStore.mainSettings);
  const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  return (
    <div className="row">
      {/* Image only needed for: Speaker Overview and mobile */}
      {item.fieldImage && isMobile && (
        <div className="col-8 image">
          <LazyLoad offset={1000}>
            <Image
              data={item.fieldImage.fieldMediaImage}
              credit={item.fieldImage.fieldCredits}
              nodeTitle={item.title}
            />
          </LazyLoad>
        </div>
      )}
      <div className={`infos ${isMobile ? "col-8" : "col-16"}`}>
        <div className="row">
          <div className="col-16 info-col">
            {!!item.fieldEvents?.length &&
              item.fieldEvents[0].fieldVenue?.title && (
                <div className="meta-infos">
                  {mainSettings?.fieldReleaseState === "all" && (
                    <div className="location">
                      {item.fieldEvents[0].fieldVenue.title}
                    </div>
                  )}
                </div>
              )}
            <h2 className="name headline-m">
              <CustomHyphenation length={8}>
                {item.title}
              </CustomHyphenation>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

const TeaserScrollOverviewContent = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const dispatch = useDispatch(),
    location = useLocation();

  return (
    <article
      className="node node-teaser teaser-scroll-overview scroll-overview-listener"
      data-index={index}
      id={encodeUrl(`${item.title}-${item.id}`)}
      data-to-scrollspy-id={encodeUrl(`${item.title}-${item.id}`)}
      data-teaser-index={index}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        className="flex-wrap"
        link={item}
        forceSameTab={true}
      >
        <Content item={item} />
      </Link>
    </article>
  );
};

export const teaserActPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  clickBehaviour: PropTypes.bool.required,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserScrollOverviewContent.propTypes = {
  item: teaserActPropTypes,
};

export default TeaserScrollOverviewContent;
