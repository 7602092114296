import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DownloadItem from "./download-item";

const ParagraphDownloadOverview = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-download-overview": true,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container">
        <div className="row">
          {content.fieldDownloads.map((downloadItem, index) => (
            <React.Fragment key={index}>
              <DownloadItem
                content={downloadItem}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphDownloadOverview.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldFile: PropTypes.shape({
      fieldPreviewImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      label: PropTypes.string.isRequired,
      fieldDescription: PropTypes.string,
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphDownloadOverview;
