import React from "react";
import DateWrapper from "./date-wrapper";
import FestivalDay from "@general-components/date-time/festival-day";

const FeaturedEvents = ({ content }) => {
  if (
    // Edge case: unpublished appearances return null, but array is not empty.
    (content.fieldAppearances?.length &&
      content.fieldAppearances.filter(Boolean).length === 0) ||
    // Appearances are empty
    (content.fieldAppearances && content.fieldAppearances.length === 0) ||
    // Appearances are not set
    !content.fieldAppearances
  ) {
    return (
      <div className={"featured-event-wrapper"}>
        <div className="title make-headline-light">TBA</div>
      </div>
    );
  }

  return (
    <div className={"featured-event-wrapper"}>
      {content.fieldAppearances.filter(Boolean).map((featuredEvent, index) => (
        <div className="featured-on-event" key={index}>
          {featuredEvent.fieldHappening?.label && (
            <div className="title make-headline-light">
              {featuredEvent.fieldHappening.label}
            </div>
          )}
          <DateWrapper content={featuredEvent} />
          <FestivalDay date={featuredEvent.fieldDateRawField?.first?.value} />
        </div>
      ))}
    </div>
  );
};

export default FeaturedEvents;
