import {FormattedMessage} from "react-intl";
import React from "react";

const MainPresenterTextInfo = ({ content }) => {
  let mainPresenter = [];

  content.fieldAppearances.map((item) => {
    if (item.fieldMainPresenterRawField?.list.length > 0) {
      mainPresenter.push(item.fieldMainPresenterRawField?.list);
    }
  })

  if (mainPresenter?.length > 0) {
    return (
      <div className="col-16 main-presenter-text-info">
        <div
          className={"text headline-subhead d-flex flex-wrap"}
        >
          <div className="label">
            <FormattedMessage id="presenter.main" />: {" "}
          </div>

          <div className="values">
            {/* @todo: Should'nt this be a single value field? */}
            {mainPresenter.map((item) =>
              <>
                {item[0].entity.fieldWebsite ? (
                  <a href={item[0].entity.fieldWebsite.uri.path}>
                    {item[0].entity.title}
                  </a>
                ) : (
                  <span>
                    {item[0].entity.title}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MainPresenterTextInfo;