import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import Tags from "../../tags/tags";
import { useLocation } from "react-router-dom";

const TeaserNewsTimelineList = ({ pagerFullPage = false, item, ...props }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-news teaser-news-timeline-list">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
        className="flex-wrap"
        to={item.url ? item.url.path : item.path.alias}
      >
        <div className="row">
          <div className="col-16 col-md-3">
            <FieldTime
              timestamp={true}
              date={
                item.publishedAtRawField?.first.value ||
                item.publishedAtRawField?.first.publishedAtOrNow
              }
              format={"DD.MM.YYYY"}
            />
          </div>

          <div className="col-16 col-md-9">
            <h3>{item.title}</h3>

            {item.fieldText && (
              <div
                className="teaser text"
                dangerouslySetInnerHTML={{
                  __html: item.fieldText,
                }}
              />
            )}

            <Tags tags={item.fieldTags} />
          </div>

          <div className="col-16 col-md-3 offset-md-1">
            {item.fieldImage && (
              <Image
                data={item.fieldImage.fieldMediaImage}
                credit={item.fieldImage.fieldCredits}
                nodeTitle={item.title}
              />
            )}
          </div>
        </div>
      </Link>
    </article>
  );
};

TeaserNewsTimelineList.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserNewsTimelineList;
