import { useSelector } from "react-redux";
import moment from "moment-timezone";

/*
* This Function ensures that the app only uses The "Hamburg Timezone", because everything is happening in Hamburg.
*/

const ForceHamburgTimezone = () => {
  const currentLanguage = useSelector((reduxStore) => reduxStore.i18n.currentLanguage);

  moment.tz.setDefault('Europe/Berlin');

  // Unfortunately "moment.tz.setDefault" changes the current locale, so we have to set it back to the correct one.
  moment.locale(currentLanguage);
};

export default ForceHamburgTimezone;