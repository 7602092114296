import React, {useRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";

import Link from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import MorphSlider from "./morph-slider";
import {useSelector} from "react-redux";
import LazyLoad from "react-lazyload";

const objectToArray = (myObject) => {
  // Only get the file urls from the object and store them as array to save data the way the three.js function needs it
  let imageArray = [];

  Object.keys(myObject).forEach((item) => {
    imageArray.push(
      myObject[item].fieldImage.fieldMediaImage.style.url
    );
  });
  return imageArray;
};

const restructure = (urls) => {
  // Moving first element to end
  let array = [...urls];
  let first = array[0];

  array.shift();
  array.push(first);

  return array;
};

const ParagraphTeaserSlider = ({ content }) => {
  const slider = useRef(),
    isMobile = useSelector((state) => state.appStore.isMobile);

  const [morphSliderContent, setMorphSliderContent] = useState([]),
    [slides, setSlides] = useState(content.fieldTeaserSlides);

  useEffect(() => {
    if (slides) {
      // Duplicate if less than 2, otherwise the slider wont work.
      if (slides.length <= 2) {
        setSlides([...slides, ...slides]);
      }

      setMorphSliderContent(objectToArray(slides));
    }
  }, [content.fieldTeaserSlides]);

  const sliderSettings = {
    arrows: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    touchMove: false,
    beforeChange: (oldIndex, newIndex) => {
      slider.current.classList.add("changing");
      setTimeout( () => {
        slider.current.classList.remove("changing");
      }, 2000);
    },
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-slider": true,
  });

  return (
    <section className={sectionClassNames}>
      <ErrorBoundary>
        {slides && (
          <>
            <React.Suspense>
              <LazyLoad offset={500}>
                <div className="slider-wrapper">
                  <MorphSlider
                    content={morphSliderContent}
                    id={`slider-${content.id}-1`}
                    isMobile={isMobile}
                  />
                  <MorphSlider
                    content={restructure(morphSliderContent)}
                    id={`slider-${content.id}-2`}
                    isMobile={isMobile}
                  />
                </div>
              </LazyLoad>
            </React.Suspense>

            <div className="content-slider-wrapper" ref={slider}>
              <Slider {...sliderSettings}>
                {slides.map((item, index) => (
                  <React.Fragment key={index}>
                    <article className="container">
                      <div className="row">
                        <div className="col-16 position-static">
                          {item.fieldHeading && (
                            <h2>{item.fieldHeading}</h2>
                          )}
                          {item.fieldText && (
                            <div
                              className="text body-m"
                              dangerouslySetInnerHTML={{
                                __html: item.fieldText,
                              }}
                            />
                          )}
                          {item.fieldLink && (
                            <>
                              <Link
                                className="link body-m"
                                link={item.fieldLink}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </article>
                  </React.Fragment>
                ))}
              </Slider>
            </div>
          </>
        )}
      </ErrorBoundary>
    </section>
  );
};

ParagraphTeaserSlider.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        fieldHeadline: PropTypes.string.isRequired,
        fieldImage: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
        fieldLink: PropTypes.shape({
          uri: PropTypes.shape({
            __typename: PropTypes.string,
            path: PropTypes.string,
            entity: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTeaserSlider;
