import React from "react";
import { useSpring, animated, config } from "react-spring";
import PropTypes from "prop-types";
import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";

const StoryTeaserContent = ({
  inView,
  contentWrapper,
  content,
  hover,
  setHoverState,
  right,
  left,
}) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: hover ? 1 : 0,
    config: { ...config.wobbly, tension: left || right ? 120 : 180 },
  });

  let style = {
    scale: x.to({
      output: [1, 1.5],
      range: [0, 1.2],
    }),
    rotate: x.to({
      output: ["0deg", "1deg", "0deg"],
      range: [0, 0.5, 1],
    }),
  };

  return (
    <div
      onMouseEnter={setHoverState}
      onMouseLeave={setHoverState}
      className={`cloud-content-teaser ${inView ? "in-view" : "not-in-view"}`}
    >
      <animated.div style={style} ref={contentWrapper}>
        <div className="inner-wrapper">
          {content.fieldBild && (
            <img
              src={content.fieldBild.entity.fieldMediaImage.style.url}
              alt={content.fieldBild.entity.fieldMediaImage?.alt}
            />
          )}
          {content.entityLabel && (
            <div className="teaser-text">
              <h2>{content.entityLabel}</h2>
            </div>
          )}
        </div>
      </animated.div>
    </div>
  );
};

StoryTeaserContent.propTypes = {
  content: PropTypes.shape(ContentCloudNodePropTypes),
  inView: PropTypes.bool.isRequired,
  contentWrapper: PropTypes.object.isRequired,
  hover: PropTypes.bool.isRequired,
  setHoverState: PropTypes.func.isRequired,
  right: PropTypes.bool.isRequired,
  left: PropTypes.bool.isRequired,
};

export default StoryTeaserContent;
