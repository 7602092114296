import React, { useEffect, useRef, useState } from "react";

import ErrorBoundary from "../../../../../error-boundary";

import TeaserVenue from "../../../../teaser-base/venue/teaser-venue";
import TeaserVenueImage from "../../../../teaser-base/venue/teaser-venue-image";
import Scrollspy from "react-scrollspy";
import { encodeUrl } from "../../../../../lib/encode-url";
import { changeActiveImage } from "../../../../../lib/change-active-image";
import { useSelector } from "react-redux";
import useOverviewContext from "../store/use-overview-context";
import CustomAnimation from "@general-components/custom-animation";
import Controls from "../components/main-controls";
import FilterControls from "../components/main-controls/filter-controls";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import usePageTransitionContext from "@js/page-transition/use-page-transition-context";

const OverviewVenues = () => {
  const { nodes, loadMoreNodes, metaData, loading } = useOverviewContext();
  const { setIsLoading } = usePageTransitionContext();


  const locationsOverview = useRef();
  const [recursiveLoadingFired, setRecursiveLoadingFired] = useState(false);

  const { hash } = useLocation();

  const isMobile = useSelector((state) => state.appStore.isMobile);

  const recursiveLoading = async (_nodes) => {
    // Search for matching elements.
    let matchingNode;

    for (const index in _nodes) {
      const currentNode = _nodes[index];
      let nodeField = encodeUrl(currentNode.label);

      if (nodeField === hash.replace("#", "")) {
        matchingNode = currentNode;
        break;
      }
    }

    if (_nodes.length >= metaData.totalRows && !matchingNode) {
      matchingNode = _nodes[_nodes.length - 1];
    }

    if (matchingNode) {
      setIsLoading(false);
      // Wait for the nodes to be rendered, then scroll to the matching node.
      setTimeout(() => {
        const matchingElement = document.getElementById(matchingNode.label);
        const position =
          matchingElement.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }, 100);
    }

    if (_nodes.length < metaData.totalRows && !loading && !matchingNode) {
      const newNodes = await loadMoreNodes(_nodes);

      // Call the recursive function again with the new nodes.
      recursiveLoading(newNodes);
    }
  };

  useEffect(() => {
    if (hash && !recursiveLoadingFired && !loading && !!nodes.length && metaData.perPage > 1) {
      setRecursiveLoadingFired(true);
      setIsLoading(true);
      recursiveLoading(nodes);
    }
  }, [hash, nodes, loading, metaData]);

  return (
    <div className="venues-overview" ref={locationsOverview}>
      <Controls>
        <FilterControls>
          <FormattedMessage id="locations.filter" />
        </FilterControls>
      </Controls>
      {!!nodes?.length && (
        <div className="container">
          {isMobile ? (
            <div className={"row"}>
              <CustomAnimation type={"appear"} isVisible={true}>
                {nodes.map((item, index) => (
                  <React.Fragment key={index}>
                    <ErrorBoundary>
                      <TeaserVenue
                        item={item}
                        index={index}
                        updateUrl={true}
                        pagerFullPage={false}
                      />
                    </ErrorBoundary>
                  </React.Fragment>
                ))}
              </CustomAnimation>
            </div>
          ) : (
            <div className="row">
              <div className={"col-8 image-wrapper"}>
                <div className={"movement-wrapper"}>
                  <Scrollspy
                    offset={-350}
                    items={nodes.map((item) => encodeUrl(item.label))}
                    componentTag={"div"}
                    currentClassName={"is-current"}
                    onUpdate={(el) => {
                      changeActiveImage(el, locationsOverview.current);
                    }}
                  >
                    {nodes.map((item, index) => (
                      <a
                        href={`#${encodeUrl(item.label)}`}
                        aria-label={item.label}
                        key={index}
                      >
                        <ErrorBoundary>
                          <span className={"sr-only"}>{item.label}</span>
                          <TeaserVenueImage
                            item={item}
                            index={index}
                            updateUrl={true}
                            pagerFullPage={false}
                          />
                        </ErrorBoundary>
                      </a>
                    ))}
                  </Scrollspy>
                </div>
              </div>
              <div className={"col-8 teaser-wrapper"}>
                <CustomAnimation type={"appear"} isVisible={true}>
                  {nodes.map((item, index) => (
                    <React.Fragment key={index}>
                      <ErrorBoundary>
                        <TeaserVenue
                          item={item}
                          index={index}
                          updateUrl={true}
                          pagerFullPage={false}
                        />
                      </ErrorBoundary>
                    </React.Fragment>
                  ))}
                </CustomAnimation>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OverviewVenues;
