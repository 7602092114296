import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
import AnchorLink from "../../anchor-link";
import {encodeUrl} from "@lib/encode-url";
import {useSelector} from "react-redux";
import MergedWords from "@js/microsites/rbx-company/general-components/merged-words";
import Link from "@general-components/link/link";

const ParagraphSectionheading = ({ content }) => {
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite),
    sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
    });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-16 col-lg-12">
            {content.fieldSetAnchorLink && (
              <AnchorLink link={content.fieldAnchorLink ? content.fieldAnchorLink : encodeUrl(content.fieldHeading)}/>
            )}
            <h2 className="section-heading">
              {microSite === "rbx-company" ? (
                <MergedWords
                  words={content.fieldHeading}
                  type={"border"}
                />
              ) : (
                <>
                  <Hyphenated language={de}>
                    {content.fieldHeading}
                  </Hyphenated>
                  {content.fieldCallToAction && content.fieldCallToAction.title && content.fieldCallToAction.uri &&
                    <>
                      {" "}<Link link={content.fieldCallToAction}/>
                    </>
                  }
                </>
              )}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphSectionheading.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
  }),
};

export default ParagraphSectionheading;
