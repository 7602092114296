import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import classNames from "classnames";
import CustomArrow from "./custom-arrow";
import PropTypes from "prop-types";
import MediaSwitch from "../../../media-switch";
import CustomAnimation from "@general-components/custom-animation";
import SpotifyButtonListen from "@js/participants/components/spotify-widget/spotify-button-listen";
import SpotifyButtonIdle from "@js/participants/components/spotify-widget/spotify-button-idle";

/* This function is responsible for the custom slider animation.
*  Params:
*  current & next: slide props given by slider
*  ref: Slider ref
*  content: given Media field
 */
const sliderMovement = (current, next, ref, content) => {
  // determine active slide, next and next but one slide
  let activeSlide = next,
    nextSlide = next + 1 <= content.fieldMultimediaRawField.list.length - 1 ? next + 1 : 0,
    nextButOneSlide = next + 2 <= content.fieldMultimediaRawField.list.length - 1 ? next + 2 : 0;

  // Set CSS Variables, god knows why
  ref.current.style.setProperty("--active-slide", next);
  ref.current.style.setProperty("--next-slide", nextSlide);
  ref.current.style.setProperty(
    "--next-but-one-slide",
    nextButOneSlide
  );

  // Remove classes
  ref.current
    .querySelector(".inner-slide.active-slide")
    .classList.remove("active-slide");
  ref.current
    .querySelector(".inner-slide.next-slide")
    .classList.remove("next-slide");
  ref.current
    .querySelector(".inner-slide.next-but-one-slide")
    .classList.remove("next-but-one-slide");

  // Add new classes
  setTimeout(() => {
    ref.current
      .querySelectorAll(".inner-slide")
      [activeSlide].classList.add("active-slide");
    ref.current
      .querySelectorAll(".inner-slide")
      [nextSlide].classList.add("next-slide");
    ref.current
      .querySelectorAll(".inner-slide")
      [nextButOneSlide].classList.add("next-but-one-slide");
  }, 0);
}

const MediaStack = ({ content }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [muted, setMuted] = useState(true);
  const slider = useRef();
  const slickSlider = useRef();

  const updateSlideIndex = (next) => {
    setSlideIndex(next);
  };

  const toggleMute = () => {
    const videos = slider.current.querySelectorAll("video");

    setMuted(!muted);

    if (videos[slideIndex]) {
      videos[slideIndex].muted = !videos[slideIndex].muted;
    }
  }

  const stopVideo = () => {
    const videos = slider.current.querySelectorAll("video");

    if (videos[slideIndex]) {
      videos[slideIndex].pause();
    }
  }

  const sliderSettings = {
    adaptiveHeight: false,
    arrows: true,
    fade: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    centerMode: false,
    infinite: true,
    nextArrow: <button aria-label="next" tabIndex={0} />,
    beforeChange: (current, next) => {
      sliderMovement(current, next, slider, content);

      stopVideo();

      if (!muted) {
        toggleMute();
      }

      updateSlideIndex(next);
    },
  };

  return (
    <section
      className={classNames({
        "paragraph media-gallery": true,
        "slider-wrapper": true,
        [`format-${content.fieldStackFormat}`]: true,
      })}
      data-darkmode={content.fieldDarkMode}
      ref={slider}
    >
      <div className="container">
        <div className="row">
          <div className={classNames({
            "col-16": true,
            "col-lg-8 col-lg-4": content.fieldStackFormat === "left",
            "offset-lg-8 col-lg-8": content.fieldStackFormat === "right",
            "offset-lg-4 col-lg-8": content.fieldStackFormat === "center",
          })}>
            <Slider
              {...sliderSettings}
              ref={slickSlider}
            >
              {content.fieldMultimediaRawField?.list?.map((item, index) => (
                <div
                  className={classNames({
                    "inner-slide": true,
                    "active-slide": index === slideIndex,
                    "next-slide": index === slideIndex + 1,
                    "next-but-one-slide": index === slideIndex + 2
                  })}
                  data-id={index}
                  key={index}
                >
                  <MediaSwitch
                    media={item.entity}
                    item={item.entity}
                    autoplay={slideIndex === index}
                    forcePlay={slideIndex === index}
                    loop={true}
                    controls={false}
                    inStack={true}
                  />
                </div>
              ))}
            </Slider>
            {content.fieldMultimediaRawField?.list?.map((captionItem, captionIndex) =>
              <React.Fragment key={captionIndex}>
                {(captionItem.customCaption || captionItem.defaultCaption || content.fieldShowControls) && (
                  <div className="caption-wrap" data-active={captionIndex === slideIndex}>

                    {captionItem.customCaption && (
                      <span className="caption">
                        {captionItem.customCaption}
                      </span>
                    )}

                    {captionItem.defaultCaption && (
                      <span className="caption">
                        {captionItem.defaultCaption}
                      </span>
                    )}

                    {(content.fieldShowControls && captionItem.entity.entityBundle === "video") &&
                      <button
                        className="toggle-mute"
                        onClick={() => toggleMute()}
                      >
                        <CustomAnimation
                          isVisible={!muted}
                          type={"appear"}
                        >
                          <SpotifyButtonListen/>
                        </CustomAnimation>
                        <CustomAnimation
                          isVisible={muted}
                          type={"appear"}
                        >
                          <SpotifyButtonIdle/>
                        </CustomAnimation>
                      </button>
                    }
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

MediaStack.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldMultimediaRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.string,
          entity: PropTypes.shape({
            fieldCaption: PropTypes.string,
            fieldCredits: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

export default MediaStack;
