import { useQuery } from "@apollo/client";
import configQuery from "./config-query.graphql";

/**
 * Uses the useQuery hook to explicitly get a config page by name.
 * Returns the reduced data as an object.
 * @param {string|array} name Config page machine name.
 * @returns {object} Returns the reduced data of a useQuery hook.
 */
const useConfigQuery = (name, language) => {
  language = language === "und" ? "de" : language;
  let languageCtxEnabled = false;
  let languageCtx = "";
  if (language) {
    languageCtx = "a:1:{i:0;a:1:{s:8:\"language\";s:2:\"" + language + "\";}}"
    languageCtxEnabled = true;
  }

  const { data, loading, error } = useQuery(configQuery, {
    variables: {
      // pass name of config as array
      name: typeof name === "string" ? [name] : name,
      languageCtx,
      languageCtxEnabled
    },
  });

  let result;
  if (data?.entityQuery.items.length > 1) {
    result = data?.entityQuery;
  } else {
    result = data?.entityQuery.items[0]
  }

  return { data: result, loading, error };
};

export default useConfigQuery;
