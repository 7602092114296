import React from "react";
import { FormattedMessage } from "react-intl";
import RBXAnimation from "@general-components/rbx-animation/rbx-animation";
import {useSelector} from "react-redux";
import RBXLogo from "@/assets/rbx-company/RBX-Logo-white.svg";

const Error404 = () => {
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  return (
    <div className="container error-404">
      <div className="row">
        <div className="col-16">
          <h1 className="text-center"><FormattedMessage id="error.not_found" /></h1>
          {microSite ? (
            <img
              src={RBXLogo}
              className="rbx-logo"
              alt={"Logo RBX"}
            />
            ) : (
            <RBXAnimation/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Error404;
