import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TeaserBase from "../../../teaser-base/teaser-base";
import useOverviewContext from "./store/use-overview-context";

// Overviews.
import OverviewProvider from "./store/overview-provider";
import OverviewTimetable from "./timetable/overview-timetable";
import OverviewVenues from "./venues/overview-venues";
import OverviewSpeakers from "./speakers/overview-speakers";
import OverviewActs from "./acts/overview-acts";
import OverviewNews from "./news/overview-news";

// Overview system shared-general-components.
import Loading from "./components/system/loading";
import NoResults from "./components/system/no-results";
import Error from "./components/system/error";
import EndlessScroll from "./components/system/endless-scroll";

const OverviewDefault = () => {
  const { nodes } = useOverviewContext();

  return (
    <div className="row nodes">
      {!!nodes && nodes.length &&
        nodes.map((node) => (
          <TeaserBase
            key={node.id}
            item={node}
            viewMode={"overview"}
          />
        ))}
    </div>
  );
}

/**
 * Renders the Overview module.
 * This component is wrapped by the overview context provider at the end of
 * this file. The whole state and logic of this module is handled by the overview context
 * located in ./store.
 * The context is only consumed in child shared-general-components to prevent rerendering the
 * whole overview component on every context change.
 */
const Overview = ({ entityBundle }) => {
  const noScrollSpy = entityBundle === "overview_acts";

  return (
    <section
      className={classNames({
        "paragraph paragraph-extended-teaser-view": true,
        [entityBundle.replace("_", "-")]: true
      })}
    >
      <div className="container no-padding">
        {(() => {
          // Render the correct overview component based on the entity bundle.
          switch (entityBundle) {
            case "overview_timetable":
              return <OverviewTimetable />;
            case "overview_acts":
              return <OverviewActs />;
            case "overview_speakers":
              return <OverviewSpeakers />;
            case "overview_venues":
              return <OverviewVenues />;
            case "overview_news":
              return <OverviewNews />;
            default:
              return <OverviewDefault />;
          }
        })()}

        {/* Render no results, error or loading shared-general-components based on context store */}
        {!noScrollSpy &&
          <NoResults/>
        }
        <Error />
        <Loading />

        {/* Render the endless scroll component. */}
        {!noScrollSpy &&
          <EndlessScroll />
        }
      </div>
    </section>
  );
};

Overview.propTypes = {
  entityBundle: PropTypes.oneOf([
    "overview_acts",
    "overview_speakers",
    "overview_venue",
    "overview_timetable",
  ]),
}

// Wrap the Overview component with the PageTransitionProvider.
const ParagraphOverview = ({ content }) => {
  return (
    <OverviewProvider content={content}>
      <Overview entityBundle={content.entityBundle} />
    </OverviewProvider>
  );
};

ParagraphOverview.propTypes = {
  viewMode: PropTypes.string,
  updateUrl: PropTypes.bool,
  content: PropTypes.shape({
    entityBundle: PropTypes.oneOf([
      "overview_acts",
      "overview_speakers",
      "overview_venues",
      "overview_timetable",
    ]),
    fieldContentType: PropTypes.oneOf([
      "news",
      "event",
      "project",
      "person",
      "venue",
      "participant",
    ]),
  }),
};

export default ParagraphOverview;
