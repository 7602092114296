import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import ErrorBoundary from "../../error-boundary";
import Image from "../../general-components/image/image";
import { FormattedMessage } from "react-intl";

const PartnerLogo = ({ content }) => {
  return (
    <div className="partner">
      {content.customCaption ? (
        <div className="headline body-m make-headline-light">{content.customCaption}</div>
      ) : (
        <>
          {content.fieldLabel ? (
            <div className="headline body-m make-headline-light">{content.entity.fieldLabel}</div>
          ) : (
            <div className="headline body-m make-headline-light placeholder" />
          )}
        </>
      )}
      {content.fieldMediaImage && (
        <Image
          data={content.fieldMediaImage}
          nodeTitle={content.fieldLabel}
        />
      )}
    </div>
  );
};

const FooterPartners = ({ content }) => {
  if (content.fieldFooterPartners) {
    return (
      <ErrorBoundary>
        <div className="partners">
          <div className="row">
            <div className="col-16">
              <h3 className="headline body-m make-headline-light">
                <FormattedMessage id="supported" />
              </h3>
            </div>
          </div>
          <div className="row">
            {content.fieldFooterPartners.map((item, index) => (
              <div className="col-8" key={index}>
                {item.fieldLink ? (
                  <a
                    href={item.fieldLink.uri.path}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <PartnerLogo content={item} />
                  </a>
                ) : (
                  <PartnerLogo content={item} />
                )}
              </div>
            ))}
          </div>
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default FooterPartners;
