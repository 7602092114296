import React, { useEffect, useState } from "react";

import participantPageQuery from "../../participants/participant.graphql";
import Presenter from "../../participants/components/presenter";
import LookingFor from "../../participants/components/looking-for";
import SocialLinks from "../../participants/components/social-links";
import Sponsors from "../../participants/components/sponsors";
import Appearances from "../shared-components/appearances/appearances";
import NodeWrapper from "@nodes/shared-components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import SpotifyWidget from "@js/participants/components/spotify-widget/spotify-widget";
import DetailPageIntro from "@nodes/shared-components/detail-page-intro";
import { useSelector } from "react-redux";
import ParagraphClickSuggestions from "@paragraphs/click-suggestions/paragraph-click-suggestions";
import CheckForChildren from "@lib/check-for-children";
import Credit from "./../shared-components/credit";

const Speaker = () => {
  const mainSettings = useSelector(
      (reduxStore) => reduxStore.appStore.mainSettings
    ),
    customHistory = useSelector(
      (reduxStore) => reduxStore.appStore.customHistory
    );

  const [backToOverviewContent, setBackToOverviewContent] = useState({
    fieldClickSuggestions: [],
  });

  const { entity, loading, error } = useNodeQuery(
    participantPageQuery,
    undefined,
    {
      onCompleted: (data) => {
        prepareBackToOverviewContent(data.route.entity);
      },
    }
  );

  /**
   * If the node is loaded, check if speaker is on Festival, Conference or both branches.
   * Get the corresponding "overview Link" and restructure the content to have them displayed by the existing module.
   * @param {String} navigateBack is used when the last visited URL is the same as the given Overview Page, to ensure we use the right Scrollposition
   */

  const prepareBackToOverviewContent = (node) => {
    let prepareBackToOverviewContent = {
      fieldClickSuggestions: [],
    };

    if (
      !!mainSettings.fieldSpeakersFestivalOverview &&
      node?.fieldBranch.includes("festival")
    ) {
      let festivalOverviewLink = {
        fieldHighlighted: true,
        fieldLink: mainSettings.fieldSpeakersFestivalOverview,
        fieldImage: mainSettings.fieldFestivalSpeakersOwImage,
        navigateBack:
          mainSettings.fieldSpeakersFestivalOverview?.uri?.path ===
          customHistory[customHistory.length - 2]?.pathname,
      };

      prepareBackToOverviewContent.fieldClickSuggestions.push(
        festivalOverviewLink
      );
    }

    if (
      !!mainSettings.fieldSpeakersConfOverview &&
      node?.fieldBranch.includes("conference")
    ) {
      let conferenceOverviewLink = {
        fieldHighlighted: true,
        fieldLink: mainSettings.fieldSpeakersConfOverview,
        fieldImage: mainSettings.fieldConfSpeakersOwImage,
        navigateBack:
          mainSettings.fieldSpeakersConfOverview?.uri?.path ===
          customHistory[customHistory.length - 2]?.pathname,
      };

      prepareBackToOverviewContent.fieldClickSuggestions.push(
        conferenceOverviewLink
      );
    }

    if (prepareBackToOverviewContent !== backToOverviewContent) {
      setBackToOverviewContent(prepareBackToOverviewContent);
    }
  };

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity && (
        <>
          <DetailPageIntro content={entity} mode={"speaker"} />

          <div className="container-fluid main-infos speaker">
            <div className="row">
              <div className={"col-16 col-xl-4 meta-info-wrapper"}>
                {entity.fieldCompany && (
                  <div
                    className={"text body-m"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldCompany }}
                  />
                )}
                {entity.fieldPosition && (
                  <div
                    className={"text body-m"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldPosition }}
                  />
                )}
                {!!entity.fieldCountry?.list?.length && (
                  <>
                    {entity.fieldCountry.list.map((country) => (
                      <div
                        className="tag black body-s d-inline-flex"
                        key={country}
                      >
                        {country.value}
                      </div>
                    ))}
                  </>
                )}
              </div>

              <div className={"col-16 col-xl-12 appearances-wrapper"}>
                <Appearances
                  detailedMode={true}
                  content={entity}
                  type={"speaker"}
                  viewMode={"detail"}
                  showAddToCalendar={true}
                  showEndDate={true}
                />
              </div>
            </div>
          </div>

          <div className="container max-width main-container">
            <div className="row text-wrapper">
              {entity.fieldBiography && (
                <div className="col-16 text-wrapper">
                  <div
                    className={"text headline-subhead"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldBiography }}
                  />
                </div>
              )}

              <Credit content={entity} />
            </div>
            <div className="row with-gaps">
              <CheckForChildren classes={"col-16 col-lg-8 left-col"}>
                <SpotifyWidget content={entity} />

                <LookingFor content={entity} />

                <Sponsors content={entity} />
              </CheckForChildren>

              <CheckForChildren classes={"col-16 col-lg-8 right-col"}>
                <Presenter content={entity} />

                <SocialLinks content={entity} />
              </CheckForChildren>

              {/* Checks if the user comes from Speaker Overview to force rhe user to use History instead of Link. */}
              {backToOverviewContent && (
                <div className="back-to-overview">
                  <ParagraphClickSuggestions
                    content={backToOverviewContent}
                    forceSameTab={true}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </NodeWrapper>
  );
};

Speaker.propTypes = {};

export default Speaker;
