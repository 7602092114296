import {FormattedMessage} from "react-intl";
import React from "react";
import DownloadIcon from "@general-components/downlad-icon";

const Credit = ({ content }) => {
  const renderCredit = content?.fieldImage?.fieldCredits && content?.fieldImage?.fieldCredits !== '„“',
  renderDownloadImage = !!content?.fieldImage;

  if (renderCredit || renderDownloadImage) {
    return (
      <div className="col-16 credit-and-image-download">
        {renderCredit &&
          <div
            className={"image-credit body-m"}
          >
            <FormattedMessage id="credit" />{" "}{content?.fieldImage?.fieldCredits.replace("© ", "").replace("©", "")}
            {renderDownloadImage &&
              <>{" "}</>
            }
          </div>
        }

        {renderDownloadImage &&
          <a
            className="image-download body-m"
            download
            href={content?.fieldImage?.fieldMediaImage?.styleDownload?.url}
            target={"_blank"}
          >
            {renderCredit &&
              <span className="divider">{"/ "}</span>
            }
            <FormattedMessage id="download.image" />
            <DownloadIcon/>
          </a>
        }
      </div>
    );
  }
}

export default Credit;