import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link from "../../../link/link";
import { Link as RouterLink } from "react-router-dom";
import ErrorBoundary from "../../../../error-boundary";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../teaser-base/project/teaser-project";
import { teaserActPropTypes } from "../../../teaser-base/act/teaser-act";
import ComponentTeaserListCarousel from "./components/component-teaser-list-carousel";
import ComponentTeaserListWrap from "./components/component-teaser-list-wrap";

/**
 * Extend for new content types as needed. Therefore copy to
 * src/js/general-general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */
const ParagraphTeaserList = ({ content }) => {
  let type = content.fieldContentType || content.entityBundle.replace(/teaser_list_/g, "").replace(/_/g, "-");

  const isCarousel = content.entityBundle === "teaser_list_news" || content.fieldDisplayMode === "carousel",
    sectionClassNames = classNames({
    "paragraph paragraph-teaser-list": true,
    [`paragraph-teaser-list-${content.fieldLimit}`]: true,
    "paragraph paragraph-teaser-list-slick": isCarousel,
    [`paragraph-teaser-list-${type}`]: true,
  });

  // If Type List is showing Participant types we cannot search for the type, we need to search for node type.
  if (type === "act" || type === "speaker") {
    type = "participant";
  }

  return (
    <section className={sectionClassNames}>
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-16 heading">
              <h2 className="headline-4xl">{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      {isCarousel && (
        <ErrorBoundary>
          <ComponentTeaserListCarousel
            manualNodes={content.fieldNodes}
            id={content.id}
            mode={content.fieldMode}
            limit={content.fieldLimit || 100}
            type={type}
            pagerFullPage={content.fieldPagerAufVollseiten ? content.id : false}
            pagerFullPageOverviewLink={content.fieldLinkOverview}
            tags={content.fieldTags}
            subject={content.fieldSubject}
            faculty={content.fieldFaculty}
            status={content.fieldStatus}
          />
        </ErrorBoundary>
      )}

      {content.fieldDisplayMode === "wrap" && (
        <div className="container">
          <div className="row">
            <ErrorBoundary>
              <ComponentTeaserListWrap
                manualNodes={content.fieldNodes}
                id={content.id}
                mode={content.fieldMode}
                limit={content.fieldLimit}
                type={type}
                pagerFullPage={
                  content.fieldPagerAufVollseiten ? content.id : false
                }
                pagerFullPageOverviewLink={content.fieldLinkOverview}
                tags={content.fieldTags}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}

      {content.fieldLinkOverview?.uri?.path && content.fieldLinkOverview?.title && (
        <div className="container cta-wrapper">
          <div className="row">
            <div className="col-16 d-md-flex">
              <ErrorBoundary>
                <RouterLink
                  to={content.fieldLinkOverview?.uri.path}
                  className="btn btn-primary more with-hover"
                >
                  {content.fieldLinkOverview?.title}
                </RouterLink>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

ParagraphTeaserList.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.string,
    fieldDisplayMode: PropTypes.oneOf(["wrap", "carousel"]),
    fieldLimit: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldContentType: PropTypes.oneOf(["news", "person", "event", "all", "acts"]),
    fieldHeading: PropTypes.string,
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fieldMode: PropTypes.oneOf(["auto", "manual"]),
    fieldLinkOverview: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldNodes: PropTypes.arrayOf(
      PropTypes.shape({
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
        teaserActPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserList;
