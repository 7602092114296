import React from "react";
import PropTypes from "prop-types";
import DownloadGallery from "./download-gallery";
import DownloadIcon from "@general-components/downlad-icon";
//import {getZipInfos} from "../../../../lib/get-zip-infos";

const DownloadItem = ({ content }) => {

  let file = content.fieldFile,
  fileInfos = {
    mimeType: content.fieldFile?.fieldMediaFile?.entity?.filemime.replace("application/", ""),
    size: Math.round((content.fieldFile?.fieldMediaFile?.entity?.filesize * 0.000001) * 100) / 100,
    elementsCount: false
  };

  return (
    <section className={"download-item col-16 col-md-8 col-lg-custom"}>
      <a
        className="download-button"
        href={file.mediaFileUrl.path}
        download
      >
        <DownloadGallery content={content.fieldDownloadThumbnailsRawField.list}/>
        <div className="lower-part">
          <div className="info-wrapper">
            {file?.label &&
              <h3 className="title">{file.label}</h3>
            }
            {(fileInfos.mimeType || fileInfos.size) &&
              <div className={"meta-info-wrapper"}>
                {fileInfos.mimeType &&
                  <span className="info mime-type">{fileInfos.mimeType}</span>
                }
                {fileInfos.size &&
                  <span className="info size">{fileInfos.size} Mb</span>
                }
              </div>
            }
          </div>
          <DownloadIcon/>
        </div>
      </a>
    </section>
  );
};

export default DownloadItem;
