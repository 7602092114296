import React, {useEffect, useRef} from "react";
import initialization from "./initialization";
import PropTypes from "prop-types";

const MorphSlider = ({ content, id, isMobile }) => {
  const morphSlider = useRef();

  let images = content;
  //let images = [];
  //images.push(`${self}/glitch-test/glitch-test-1.jpg`, `${self}/glitch-test/glitch-test-2.jpg`, `${self}/glitch-test/glitch-test-3.jpg`);

  useEffect(() => {
    // Initialize Slider/Three.js part after correct rendering
    if (typeof window !== "undefined" && !!images?.filter(Boolean).length) {
      initialization(
        morphSlider.current,
        id,
        isMobile
      );
    }
  }, [images, id, isMobile])

  return (
    <div className="morph-slider" ref={morphSlider}>
      {content && images && (
        <div
          id={id}
          data-images={JSON.stringify(images)}
          data-displacement=""
          className="canvas-wrapper"
        />
      )}
    </div>
  );
}

MorphSlider.propTypes = {
  content: PropTypes.array,
  id: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default MorphSlider;
