import React from "react";
import useOverviewContext from "../store/use-overview-context";
import moment from "moment-timezone";
import Controls from "../components/main-controls";
import SortControls from "../components/main-controls/sort-controls";
import _ from "lodash";
import TeaserAppearanceTimetable from "@teasers/appearance/teaser-appearance-timetable";
import CustomAnimation from "@general-components/custom-animation";
import DayControls from "../components/main-controls/day-controls";
import { FormattedMessage, useIntl } from "react-intl";
import { drupalFormat } from "@js/config";
import classNames from "classnames";
import FilterControls from "../components/main-controls/filter-controls";
import ChevronDown from "@/assets/ui/chevron-down-black.svg";
import Scrollspy from "../components/main-controls/scrollspy";
import { useSelector } from "react-redux";
import {sortByDate} from "@lib/sort-by-date";
/**
 * Renders the timetable overview component.
 */
const OverviewTimetable = () => {
  const isMobile = useSelector((state) => state.appStore.isMobile);
  const releaseState = useSelector((state) => state.appStore.releaseState);
  const { nodes, searchParams, setScrollspyOpen } = useOverviewContext();
  const sort = searchParams.get("sort") || "field_date-ASC";
  const [sortField, sortOrder] = sort.split("-");

  const weekday = searchParams.getAll("weekday");

  const intl = useIntl();

  // Prepare the groupBy function based on the sort field.
  // @todo fri: Not setting a venue on a appearance causes not showing the appearance!
  const groupByFunc = (() => {
    switch (sort) {
      case "field_date-ASC":
        return (node) =>
          moment
            .unix(node.fieldDateRawField?.first?.value)
            .format("DD.MM.YYYY HH:[00]");
      case "field_venue-ASC":
        return (node) =>
          node.fieldVenueLocation?.label || intl.formatMessage({ id: "tba" });
      default:
        return null;
    }
  })();

  // Group the nodes by the lodash groupBy function.
  let groupedNodes = [];
  if (nodes?.length && nodes[0].fieldDateRawField)
    groupedNodes = _.groupBy(nodes, groupByFunc);

  return (
    <>
      <Controls>
        <div className="col scrollspy">
          {!!weekday?.length &&
            sortField !== "field_venue" &&
            releaseState.showDate && (
              <Scrollspy
                type={"times"}
                groupedNodes={groupedNodes}
                field="fieldDateRawField.first.value"
              />
            )}
          {/* {sortField === "field_venue" && (
            <Scrollspy
              type={"chars"}
              groupedNodes={groupedNodes}
              field="fieldVenueLocation.label"
            />
          )} */}
        </div>
        <div className="col filters d-flex justify-content-center">
          <div className="container-fluid">
            <div className={"row"}>
              {releaseState.showDate && <SortControls />}
              {/* {sort.includes("field_date") && <DayControls days={Object.keys(groupedNodes)} />} */}
              {releaseState.showDay && <DayControls />}

              <FilterControls>
                <FormattedMessage id="filter" />
              </FilterControls>
            </div>
          </div>
        </div>
      </Controls>

      <div className="timetable-main">
        <CustomAnimation type={"appear"} isVisible={true}>
          {Object.entries(groupedNodes).length &&
            Object.entries(groupedNodes).map(([key, group]) => (
              <div
                className="group-wrapper row"
                id={moment(key, "DD.MM.YYYY HH:mm").format("MM-DD-HH:mm")}
                key={key}
              >
                <div
                  className={classNames({
                    "group-by-label": true,
                    "venue headline-xl blurred-background viewport-width":
                      sort === "field_venue-ASC",
                    date: sort === "field_date-ASC",
                  })}
                >
                  {releaseState.showDate && (
                    <div className="container">
                      <div className="row">
                        <div className="col-16">
                          <div className="label">
                            {sort === "field_date-ASC" && !!weekday?.length && (
                              <>
                                {isMobile ? (
                                  <button
                                    onClick={() => setScrollspyOpen(true)}
                                    tabIndex={1}
                                    aria-label={"Zeitumschalter öffnen"}
                                  >
                                    {moment(key, "DD.MM.YYYY HH:mm").format(
                                      "HH:mm"
                                    )}
                                    <img
                                      className="chevron"
                                      src={ChevronDown}
                                      alt={"chevron down icon"}
                                    />
                                  </button>
                                ) : (
                                  <div>
                                    {moment(key, drupalFormat).format("HH:mm")}
                                  </div>
                                )}
                              </>
                            )}
                            {sort === "field_venue-ASC" && (
                              <div>{key}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {sortByDate(group).map((node) => (
                  <TeaserAppearanceTimetable key={node.id} item={node} />
                ))}
              </div>
            ))}
        </CustomAnimation>
      </div>
    </>
  );
};

OverviewTimetable.propTypes = {};

export default OverviewTimetable;
