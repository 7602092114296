import React from "react";
import { useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { defaultFilters } from "@paragraphs/overview/store/overview-provider";
import useOverviewContext from "@paragraphs/overview/store/use-overview-context";
import Hyphenate from "@general-components/custom-hyphenation";

const ButtonReset = () => {
  const { resetForm } = useFormikContext();
  const { searchParams, setSearchParams } = useOverviewContext();
  const intl = useIntl();

  const ignoreKeys = ["weekday", "sort"];

  const resetHandler = (e) => {
    e.preventDefault();
    resetForm({ values: defaultFilters });

    window.scrollTo({
      top: document.querySelector(".paragraph-extended-teaser-view")?.offsetTop,
      behavior: "smooth",
    });

    setSearchParams((_searchParams) => {
      for (const key of searchParams.keys()) {
        if (!ignoreKeys.includes(key)) _searchParams.delete(key);
      }
      return _searchParams;
    });
  };

  return (
    <button
      className="btn btn-primary reset"
      onClick={resetHandler}
      type="reset"
    >
      {intl.formatMessage({ id: "reset_filter" })}
    </button>
  );
};

export default ButtonReset;
