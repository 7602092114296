import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import ParagraphFormular from "../../general-components/content-base/paragraphs/webform/paragraph-form";
import webformSubmissionQuery from "./webform-submission.graphql";
import ParagraphSectionheading from "../../general-components/content-base/paragraphs/section-heading/paragraph-sectionheading";
import { useParams } from "react-router-dom";

const WebformPage = (props) => {
  const [submissionData, setSubmissionData] = useState(null);
  const { submissionId, webformId } = useParams();

  const { loading } = useQuery(webformSubmissionQuery, {
    variables: {
      sid: submissionId,
    },
    skip: !submissionId,
    onCompleted: (data) =>
      setSubmissionData(JSON.parse(data.webformSubmissionById.data)),
  });

  if (loading) {
    return null;
  }

  return (
    <div>
      <ParagraphSectionheading
        content={{
          fieldHeading: webformId.replace(/_/g, " "),
        }}
      />
      <ParagraphFormular
        content={{
          fieldFormularAuswahl: { targetId: webformId },
        }}
        defaultInitialValues={submissionData}
        submissionId={submissionId}
      />
    </div>
  );
};

export default WebformPage;
