import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { states } from "../states";
import classNames from "classnames";

const CheckboxesField = ({ item }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );

  return (
    <div
      className={classNames({
        "form-group checkboxes": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={item.id}>
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      {Object.keys(item["#options"]).map((key) => {
        return (
          <div
            key={`${item.id}_${item["#options"][key]}`}
            className="form-check"
          >
            <Field
              className="form-check-input"
              type="checkbox"
              name={item.id}
              id={key}
              onChange={handleChange}
              onBlur={handleBlur}
              value={key}
              required={
                (!!item["#required"] || required) && !optional && visible
              }
              disabled={!enabled || disabled}
            />
            <label className="form-check-label" htmlFor={item["#options"][key]}>
              {item["#options"][key]}
            </label>
          </div>
        );
      })}

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

CheckboxesField.propTypes = {
  item: PropTypes.shape({
    "#multiple": PropTypes.number,
    "#multiple_error": PropTypes.string,
    "#options": PropTypes.objectOf(PropTypes.string),
    "#default_value": PropTypes.string,
    "#description": PropTypes.string,
    "#flex": PropTypes.number,
    "#maxlength": PropTypes.number,
    "#minlength": PropTypes.number,
    "#placeholder": PropTypes.string,
    "#required": PropTypes.bool,
    "#required_error": PropTypes.string,
    "#states": PropTypes.object,
    "#submit__label": PropTypes.string,
    "#title": PropTypes.string,
    "#type": PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default CheckboxesField;
