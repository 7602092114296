import React from "react";
import PropTypes from "prop-types";
import {restHostBackend} from "@js/config";

const BrandLogo = ({ item }) => {
  const logoUrl = item?.fieldLogo?.fieldMediaImage?.entity?.filemime === "img/jpeg" ? `${restHostBackend}${item?.fieldLogo?.fieldMediaImage?.entity?.uriRawField?.first?.url}` : item?.fieldLogo?.fieldMediaImage?.entity?.uriRawField?.first?.url;
  return (
    <div className="logo-wrapper">
      {item?.fieldLogo ? (
        <img
          src={logoUrl}
          alt={`Logo: ${item.label}`}
          className="logo"
        />
      ) : (
        <div className="logo-fallback">
          <span className={"headline-s"}>{item?.label}</span>
        </div>
      )}
    </div>
  );
};

export const BrandLogoPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

BrandLogo.propTypes = {
  item: BrandLogoPropTypes
};

export default BrandLogo;