import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import SpotifyButtonIdle from "@js/participants/components/spotify-widget/spotify-button-idle";
import SpotifyButtonListen from "@js/participants/components/spotify-widget/spotify-button-listen";
import CustomAnimation from "@general-components/custom-animation";

const SpotifyWidget = ({ content }) => {

  if (content?.fieldSpotify) {
    const [spotifyPlaying, setSpotifyPlaying] = useState(false);

    const embedControllerRef = useRef(null);

    // Update this function to include 'show' type
    const extractSpotifyData = (url) => {
      const regexArtist = /artist\/([a-zA-Z0-9]+)/,
        regexAlbum = /album\/([a-zA-Z0-9]+)/,
        regexEpisode = /episode\/([a-zA-Z0-9]+)/,
        regexShow = /show\/([a-zA-Z0-9]+)/;

      let match = url.match(regexArtist),
        type = "artist";

      if (!match) {
        match = url.match(regexAlbum);
        type = "album";
      }
      if (!match) {
        match = url.match(regexEpisode);
        type = "episode";
      }
      if (!match) {
        match = url.match(regexShow);
        type = "show"; // Add 'show' type
      }

      return {
        id: match ? match[1] : null,
        type: type,
      };
    };

    const spotifyData = extractSpotifyData(content?.fieldSpotify?.uri?.path);

    if (spotifyData?.id) {
      useEffect(() => {
        const script = document?.createElement("script");
        script.src = "https://open.spotify.com/embed-podcast/iframe-api/v1";
        script.async = true;
        document?.body.appendChild(script);

        window.onSpotifyIframeApiReady = (IFrameAPI) => {
          const element = document?.getElementById("embed-iframe");
          const options = {
            uri: `spotify:${spotifyData.type}:${spotifyData.id}`,
          };
          const callback = (EmbedController) => {
            embedControllerRef.current = EmbedController;
          };
          IFrameAPI.createController(element, options, callback);
        };

        return () => {
          document?.body.removeChild(script);
          if (window.onSpotifyIframeApiReady) {
            delete window.onSpotifyIframeApiReady;
          }
        };
      }, [spotifyData]);

      const togglePlay = () => {
        if (embedControllerRef.current) {
          embedControllerRef.current.togglePlay();
          setSpotifyPlaying(!spotifyPlaying);
        }
      };

      return (
        <div className="spotify-widget">
          <div id="embed-iframe" />
          {typeof window !== "undefined" &&
            document?.querySelector(
              ".detail-page-intro .title-wrapper h1"
            ) && (
              <>
                {createPortal(
                  <button
                    className="toggle-spotify"
                    onClick={() => togglePlay()}
                  >
                    <CustomAnimation
                      isVisible={spotifyPlaying}
                      type={"appear"}
                    >
                      <SpotifyButtonListen />
                    </CustomAnimation>
                    <CustomAnimation
                      isVisible={!spotifyPlaying}
                      type={"appear"}
                    >
                      <SpotifyButtonIdle />
                    </CustomAnimation>
                  </button>,
                  document?.querySelector(
                    ".detail-page-intro .title-wrapper h1"
                  )
                )}
              </>
            )}
        </div>
      );
    }
  }
  return null;
};

export default SpotifyWidget;
