import React from "react";

const MetaInfos = ({ content }) => {
  return (
    <div className="meta-infos">
      {/*content.fieldParticipantType && content.fieldParticipantType !== "act" &&
        <div className="meta-info concert body-m">
          {content.fieldParticipantType?.capitalize()}
        </div>
      */}

      {content.fieldMood && (
        <div className="meta-info mood body-m">{content.fieldMood.name}</div>
      )}

      {content.fieldGenre &&
        content.fieldGenre.map((genre, genreId) => (
          <div className="meta-info genre body-m" key={genreId}>
            {genre.name}
          </div>
        ))}

      {content.fieldSpokenLanguage &&
        content.fieldSpokenLanguage.map((language, languageId) => (
          <div className="meta-info language body-m" key={languageId}>
            {language.fieldLanguageId}
          </div>
        ))}

      {!!content.fieldCountry?.list?.length && (
        <>
          {content.fieldCountry.list.map((country) => (
            <div className="meta-info country body-m" key={country}>
              {country.value}
            </div>
          ))}
        </>
      )}

      {content.fieldInvitationOnly && (
        <div className="meta-info invitation-only body-m">Invitation only</div>
      )}
    </div>
  );
};

export default MetaInfos;
