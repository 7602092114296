import React, {useRef} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link, { LinkPropType } from "../../../link/link";
import ClickSuggestion from "./click-suggestion";
import { useNavigate } from "react-router-dom";

const ParagraphClickSuggestions = ({ content, forceSameTab }) => {
  const clickSuggestions = useRef();
  const navigate = useNavigate();

  const sectionClassNames = classNames({
    "paragraph paragraph-click-suggestions": true,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
      ref={clickSuggestions}
    >
      <div className="container">
        <div className="row">
          {content.fieldHeading && (
            <div className="col-16">
              <h2>{content.fieldHeading}</h2>
            </div>
          )}
          <div className="col-16 links">
            <ul className="ctas">
              {content.fieldClickSuggestions && content.fieldClickSuggestions.map(
                (link, index) => (
                  <li
                    className={
                      link.fieldHighlighted
                        ? "highlighted"
                        : "standard"
                    }
                    key={index}
                  >
                    {link.navigateBack ? (
                      <button onClick={() => navigate(-1)}>
                        <ClickSuggestion
                          content={link}
                        />
                      </button>
                    ) : (
                      <Link
                        link={link.fieldLink}
                        forceSameTab={forceSameTab}
                      >
                        <ClickSuggestion
                          content={link}
                        />
                      </Link>
                    )}
                    {/* Text needs to be outside to ensure it can be marked and can contain links. Otherwise there would be nested links */}
                    {link.fieldText && (
                      <div className="highlighted-text col">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: link.fieldText,
                          }}
                        />
                      </div>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphClickSuggestions.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldDarkMode: PropTypes.bool,
    fieldClickSuggestions: PropTypes.arrayOf({
      fieldHeading: PropTypes.string,
      fieldSubline: PropTypes.string,
      fieldImage: PropTypes.object,
      fieldLink: PropTypes.shape({
        url: PropTypes.shape({
          routed: PropTypes.bool,
          path: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default ParagraphClickSuggestions;
