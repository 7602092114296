import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";
import TimetableMetaInfos from "@teasers/appearance/components/timetable-meta-infos";
import TimetableAccessibilityInfos from "@teasers/appearance/components/timetable-accessibility-infos";
import CustomHyphenation from "./../../custom-hyphenation"
import classNames from "classnames";

const TeaserAppearanceTimetable = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp),
    isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  {/*
  * If the teaser is rendering an Brand-based Content (eg: Conference Content or Arts & Words)
  * We have every content inside of the event itself.
  * If the teaser is rendering a Concert, we do not want to show the Events Title, Image and more.
  * In this case we want to render these infos directly from the artist.
  */}

  const location = useLocation(),
    type = item.fieldHappening?.fieldEventType ? item.fieldHappening?.fieldEventType?.name : item.fieldHappening?.fieldParticipantCategory?.name,
    typeDependingContent = item.fieldHappening;

  const teaserClassNames = classNames({
    "node node-teaser teaser-event masked timetable col-16": true,
    "with-image": typeDependingContent?.fieldImage,
  });

  return (
    <article
      className={teaserClassNames}
      data-index={index}
      data-entity-id={item.id}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        className="row"
        to={typeDependingContent?.uri?.path || typeDependingContent?.path.alias}
      >
        <div className="col-16 col-lg-3 meta-infos">
          <TimetableMetaInfos
            content={item}
            type={type}
          />
        </div>
        <div className="col-16 col-lg-10 title">
          {typeDependingContent?.fieldSubline &&
            <h5 className="subline body-m make-headline-light">{typeDependingContent.fieldSubline}</h5>
          }
          {typeDependingContent?.title &&
            <h4 className="name headline-m">
              <CustomHyphenation length={15}>
                {typeDependingContent.title}
              </CustomHyphenation>
            </h4>
          }
          {isMobile &&
            <TimetableAccessibilityInfos
              content={item}
            />
          }
        </div>
        {typeDependingContent?.fieldImage?.fieldMediaImage && (
          <div className="image col-16 col-lg-3 image">
            <div className="mask-wrapper">
              <Image
                data={typeDependingContent?.fieldImage.fieldMediaImage}
                credit={typeDependingContent?.fieldImage.fieldCredits}
                nodeTitle={typeDependingContent?.title}
              />
            </div>
          </div>
        )}
      </Link>
    </article>
  );
};

export const TeaserEventTimetablePropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserAppearanceTimetable.propTypes = {
  item: TeaserEventTimetablePropTypes
};

export default TeaserAppearanceTimetable;