import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import classNames from "classnames";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";
import { teaserEventPropTypes } from "./teaser-event";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";
import { useLocation } from "react-router-dom";

const TeaserEventEinzelDoppel = (props) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article
      className={`node node-${props.item.id} node-teaser teaser-event teaser-doppel-einzel`}
    >
      <EditButton
        adminApp={adminApp}
        entityId={props.item.id}
        destinationRoute={location.pathname}
      />
      <div
        className={classNames({
          row: true,
          "flex-row-reverse": props.single,
        })}
      >
        <div
          className={classNames({
            "col-16": true,
            "col-md-8": props.single,
          })}
        >
          {props.item.fieldImage !== null && (
            <Image
              data={props.item.fieldImage.fieldMediaImage}
              credit={props.item.fieldImage.fieldCredits}
              nodeTitle={props.item.title}
            />
          )}
        </div>

        <div
          className={classNames({
            "col-16": true,
            "col-md-8": props.single,
          })}
        >
          <div className="row">
            <div className="col-16 col-md-6">
              <div className="meta-date top-line">
                <span>
                  {moment
                    .utc(props.item.fieldDateRawField?.list.value)
                    .local()
                    .format("ddd")}
                </span>
                {moment
                  .utc(props.item.fieldDateRawField?.list.value)
                  .local()
                  .format("HH:mm") !== "00:00" && (
                  <span>
                    {moment
                      .utc(props.item.fieldDateRawField?.list.value)
                      .local()
                      .format("HH:mm")}{" "}
                    Uhr
                  </span>
                )}
              </div>
              <div className="date">
                <span>
                  {moment
                    .utc(props.item.fieldDateRawField?.list.value)
                    .local()
                    .format("DD.MM.YYYY")}
                </span>
              </div>
              <div className="location">{props.item.fieldLocation}</div>
              {props.item.fieldDateRawField?.list.endValue &&
                !props.item.fieldShowDuration && (
                  <div className="duration">
                    Dauer{" "}
                    {Math.round(
                      moment
                        .duration(
                          moment
                            .utc(props.item.fieldDateRawField?.list.endValue)
                            .local()
                            .diff(
                              moment
                                .utc(props.item.fieldDateRawField?.list.value)
                                .local()
                            )
                        )
                        .asHours()
                    )}{" "}
                    Std.
                  </div>
                )}
              <div className="buttons">
                <a
                  href={getEncodedIcalUrl(
                    props.item.fieldDate,
                    props.item.fieldLocation,
                    props.item.title,
                    props.item.fieldText
                  )}
                  className="btn btn-primary"
                >
                  <FormattedMessage id="event.remember" />
                </a>
                {props.item.fieldTicketlink && (
                  <a
                    href={props.item.fieldTicketlink.uri.path}
                    className="btn btn-primary"
                  >
                    <FormattedMessage id="event.ticket" />
                  </a>
                )}
              </div>
            </div>
            <div className="col-16 col-md-10">
              {props.item.fieldTags.length > 0 && (
                <span className="top-line">{props.item.fieldTags[0].name}</span>
              )}
              <h3>{props.item.title}</h3>
              <div
                className="text teaser-text d-none d-lg-block"
                dangerouslySetInnerHTML={{
                  __html: `${props.item.fieldText} <a href="${
                    props.item.url ? props.item.url.path : props.item.path.alias
                  }">${props.intl.formatMessage({
                    id: "read_more",
                  })}</a>`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

TeaserEventEinzelDoppel.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  single: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(TeaserEventEinzelDoppel);
