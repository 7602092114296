import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AnchorLink from "../../anchor-link";
import MergedWords from "@js/microsites/rbx-company/general-components/merged-words";
import {useSelector} from "react-redux";
import CustomHyphenation from "@general-components/custom-hyphenation";

/**
 * A module representing a text paragraph.
 */
const ParagraphText = ({ content, viewMode }) => {
  const textModule = useRef();

  // set a shadow element for hover of links, since we need the other elements for border and icon.
  useEffect(() => {

    // get all links inside of the text module
    let links = textModule?.current.querySelectorAll("a");

    // Loop through all links, but only get highlighted Links and Downloads
    [...links].map((link) => {
      if (link.getElementsByClassName('highlighted-link')[0] || link.dataset.entityType === "media" ) {
        // Create a span that can work as a background blur hover effect and append it to the links span-child.
        let hoverElement = document.createElement("span");

        hoverElement.classList.add("hover-element");

        if (link?.querySelector("span")) {
          link?.querySelector("span").appendChild(hoverElement);
          link?.classList.add("highlighted-link-wrapper");
        } else {
          link?.appendChild(hoverElement);
        }
      }
    });
  }, []);

  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite),
    sectionClassNames = classNames({
      "paragraph paragraph-text": true,
      "no-padding-bottom": content.fieldReduceSpacing,
      "orientation-left": content.fieldTextOrientation === "left",
      "orientation-right": content.fieldTextOrientation === "right",
      "show-border": content.fieldShowBorder
  }),
    gridSizes = classNames({
      "text-wrapper": true,
      "col-16 col-lg-8 standard": viewMode !== "collapse" && !microSite,
      "col-16 col-lg-7 standard": viewMode !== "collapse" && microSite === "rbx-company",
      "offset-lg-4": viewMode !== "collapse" && !content.fieldHeading && content.fieldTextOrientation === "left",
      "col-16": viewMode === "collapse",
      "offset-lg-8": viewMode !== "collapse" && !content.fieldHeading,
  }),
    titleWrapperClasses = classNames({
      "title-wrapper col-16": true,
      "col-lg-8": viewMode !== "collapse"
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
      ref={textModule}
    >
      {/*<div className={"border"}/>*/}
      <div className="container">
        <div className="row">
          {content.fieldHeading &&
            <div className={titleWrapperClasses}>
              {content.fieldAnchorLink && (
                <AnchorLink link={content.fieldAnchorLink}/>
              )}
              {microSite === "rbx-company" && viewMode !== "collapse" ? (
                  <h2 className="headline">
                    <MergedWords
                      words={content.fieldHeading}
                      type={"border"}
                    />
                  </h2>
              ) : (
                <h2 className="headline">
                  <CustomHyphenation length={18}>
                    {content.fieldHeading}
                  </CustomHyphenation>
                </h2>
              )}
            </div>
          }
          <div className={gridSizes}>
            <div
              className="text body-m"
              dangerouslySetInnerHTML={{
                __html: content.fieldText,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ParagraphText;
