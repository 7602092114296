import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";
import { useQuery } from "@apollo/client";

import teaserNodeQueryEvent from "../../../teaser-base/queries/teaser-node-query-event.graphql";
import TeaserBase from "../../../teaser-base/teaser-base";
import { calendarMonthSetMonthAction } from "./actions-calendar-month";
import ErrorBoundary from "../../../../error-boundary";

const getDaysInMonth = (month, year) => {
  let days = [];

  for (let i = 1; i <= moment(month, "MMMM").daysInMonth(); i++) {
    // TODO: If i = 1, then i = 01.
    days = [
      ...days,
      moment(
        `${moment(year, "YYYY").format("YYYY")}-${moment(month, "MMMM").format(
          "MM"
        )}-${i}`
      ),
    ];
  }

  return days;
};

const mapDatesOnInit = (date, type, staticDate) => {

  if (date === "this_month") {
    if (type === "month") {
      return moment().format("MMMM");
    }

    return moment().format("YYYY");
  }
  if (date === "next_month") {
    if (type === "month") {
      return moment().add(1, "month").format("MMMM");
    }

    return moment().add(1, "month").format("YYYY");
  }

  if (type === "month") {
    return moment.utc(staticDate, "YYYY-MM-DDTHH:mm:ss").local().format("MMMM");
  }

  return moment.utc(staticDate, "YYYY-MM-DDTHH:mm:ss").local().format("YYYY");
};

const ParagraphCalendarMonth = ({ content }) => {
  const dispatch = useDispatch();
  const calendarConfig = useSelector((state) => state.calendarMonth.months);

  const mappedMonth = mapDatesOnInit(
    content.fieldStartMonth,
    "month",
    content.fieldStaticStartMonth?.value
  );
  const mappedYear = mapDatesOnInit(
    content.fieldStartMonth,
    "year",
    content.fieldStaticStartMonth?.value
  );

  const [datesInMonth, setDatesInMonth] = useState(
    getDaysInMonth(mappedMonth, mappedYear)
  );

  const [currentDate, setCurrentDate] = useState({
    day: false,
    month: mappedMonth,
    year: mappedYear,
  });

  const [currentDayEvents, setCurrentDayEvents] = useState([]);

  // Logic for getting calendar query variables.
  const config = calendarConfig.filter(
    (calendarConfig) => calendarConfig.id === content.id
  );

  let end_date = moment().endOf("month").format("YYYY-MM-DD"),
    date = moment().startOf("month").format("YYYY-MM-DD");

  if (config.length) {
    end_date = config[0].date.endOf("month").format("YYYY-MM-DD");
    date = config[0].date.startOf("month").format("YYYY-MM-DD");
  } else {
    const currentMonth = mapDatesOnInit(content.fieldStartMonth, "month"),
      currentYear = mapDatesOnInit(content.fieldStartMonth, "year");

    end_date = moment(`${currentMonth}-${currentYear}`, "MMMM-YYYY")
      .endOf("month")
      .format("YYYY-MM-DD");
    date = moment(`${currentMonth}-${currentYear}`, "MMMM-YYYY")
      .startOf("month")
      .format("YYYY-MM-DD");
  }

  const { data } = useQuery(teaserNodeQueryEvent, {
    variables: {
      type: "event",
      end_date: end_date,
      date: date,
    },
  });

  const openCalendar = (item) => {
    if (currentDate.day === item) {
      setCurrentDate((prev) => ({
        ...prev,
        day: false,
      }));
      setCurrentDayEvents([]);
    } else {
      setCurrentDate((prev) => ({
        ...prev,
        day: item,
      }));
      setCurrentDayEvents(
        data.entityQuery.items.filter(
          (event) =>
            moment
              .utc(event.fieldDateRawField?.list.value, "YYYY-MM-DDTHH:mm:ss")
              .local()
              .format("YYY-MM-DD") === moment(item).format("YYY-MM-DD")
        )
      );
    }
  };

  const monthPager = (direction) => {
    setCurrentDate((prev) => ({
      ...prev,
      day: false,
    }));
    setCurrentDayEvents([]);

    if (direction === "next") {
      const month = moment(datesInMonth[0]).add(1, "months");

      setCurrentDate({
        day: currentDate.day,
        month: month.format("MMMM"),
        year: month.format("YYYY"),
      });

      setDatesInMonth(
        getDaysInMonth(month.format("MMMM"), month.format("YYYY"))
      );

      dispatch(
        calendarMonthSetMonthAction({
          date: month,
          id: content.id,
        })
      );
    } else {
      const month = moment(datesInMonth[0]).subtract(1, "months");

      setCurrentDate({
        day: currentDate.day,
        month: month.format("MMMM"),
        year: month.format("YYYY"),
      });

      setDatesInMonth(
        getDaysInMonth(month.format("MMMM"), month.format("YYYY"))
      );

      dispatch(
        calendarMonthSetMonthAction({
          date: month,
          id: content.id,
        })
      );
    }
  };

  const handleKeyDownPager = (event, action) => {
    if (event.key === "Enter") {
      monthPager(action);
    }
  };

  const handleOpenCalendar = (event, item) => {
    if (event.key === "Enter") {
      openCalendar(item);
    }
  };

  const sectionClassNames = classNames({
      "paragraph paragraph-calendar-month": true,
    }),
    beginOfMonthOffsetDays = moment(datesInMonth[0]).format("e"),
    endOfMonthOffsetDays =
      6 -
      parseInt(moment(datesInMonth[datesInMonth.length - 1]).format("e"), 10);

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 d-flex justify-content-between">
            {content.fieldShowMonthSwitch && (
              <div
                className="pager"
                role="button"
                tabIndex={0}
                onClick={() => monthPager("prev")}
                onKeyDown={(event) => handleKeyDownPager(event, "prev")}
              >
                <ArrowLeft size={32} color="black" />
              </div>
            )}
            <h3 className="calendar-heading">
              {currentDate.month} {currentDate.year}
            </h3>
            {content.fieldShowMonthSwitch && (
              <div
                className="pager"
                role="button"
                tabIndex={0}
                onClick={() => monthPager("next")}
                onKeyDown={(event) => handleKeyDownPager(event, "next")}
              >
                <ArrowRight size={32} color="black" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-16">
          <div className="row header">
            {["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((item, index) => (
              <div
                key={index}
                className={classNames({
                  "col cal-header-cell": true,
                  "offset-1": index === 0,
                })}
              >
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="row">
            {[...Array(parseInt(beginOfMonthOffsetDays, 10))].map(
              (item, index) => (
                <div
                  key={index}
                  className={classNames({
                    "col empty-cal-cell": true,
                    "offset-1": index === 0,
                  })}
                />
              )
            )}

            {datesInMonth.map((item, index) => (
              <React.Fragment key={index}>
                {item.isValid() && (
                  <>
                    <div
                      className={classNames({
                        "col cal-cell": true,
                        "has-events":
                          data &&
                          data.entityQuery &&
                          data.entityQuery.items.filter(
                            (event) =>
                              moment
                                .utc(
                                  event.fieldDateRawField?.list.value,
                                  "YYYY-MM-DDTHH:mm:ss"
                                )
                                .local()
                                .format("YYYY-MM-DD") ===
                              moment(item).format("YYYY-MM-DD")
                          ).length > 0,
                        [`cell-${index}`]: true,
                        active:
                          moment(currentDate.day).format("DD") ===
                          moment(item).format("DD"),
                        "offset-1":
                          (parseInt(beginOfMonthOffsetDays, 10) +
                            parseInt(index, 10)) %
                            7 ===
                          0,
                      })}
                    >
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => openCalendar(item)}
                        onKeyDown={(event) => handleOpenCalendar(event, item)}
                      >
                        {moment(item).format("DD")}
                      </span>
                    </div>

                    {(index + parseInt(beginOfMonthOffsetDays, 10) + 1) % 7 ===
                      0 && (
                      <div
                        className={classNames({
                          "col-16 cal-content-cell": true,
                          [`cell-${index}`]: true,
                          active:
                            currentDate.day &&
                            currentDayEvents.length > 0 &&
                            parseInt(
                              moment(currentDate.day).endOf("week").format("D"),
                              10
                            ) ===
                              index + 1,
                        })}
                      >
                        {currentDate.day &&
                          currentDayEvents.length > 0 &&
                          parseInt(
                            moment(currentDate.day).endOf("week").format("D"),
                            10
                          ) ===
                            index + 1 && (
                            <>
                              {currentDayEvents.map((eventItem, eventIndex) => (
                                <ErrorBoundary key={eventIndex}>
                                  <TeaserBase item={eventItem} />
                                </ErrorBoundary>
                              ))}
                            </>
                          )}
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}

            {Number.isInteger(endOfMonthOffsetDays) &&
              [...Array(endOfMonthOffsetDays)].map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    "col empty-cal-cell": true,
                  })}
                />
              ))}

            {(beginOfMonthOffsetDays +
              endOfMonthOffsetDays +
              datesInMonth.length) %
              7 !==
              0 && (
              <div
                className={classNames({
                  "col-16 cal-content-cell": true,
                  active:
                    currentDate.day &&
                    currentDayEvents.length > 0 &&
                    parseInt(
                      moment(currentDate.day).endOf("week").format("D"),
                      10
                    ) < parseInt(moment(currentDate.day).format("D"), 10),
                })}
              >
                {currentDate.day &&
                  currentDayEvents.length > 0 &&
                  parseInt(
                    moment(currentDate.day).endOf("week").format("D"),
                    10
                  ) < parseInt(moment(currentDate.day).format("D"), 10) && (
                    <>
                      {currentDayEvents.map((eventItem, eventIndex) => (
                        <ErrorBoundary key={eventIndex}>
                          <TeaserBase item={eventItem} />
                        </ErrorBoundary>
                      ))}
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCalendarMonth.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.string,
    fieldShowMonthSwitch: PropTypes.bool,
    fieldStartMonth: PropTypes.oneOf(["this month", "next month", "static"]),
    fieldStaticStartMonth: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

export default ParagraphCalendarMonth;
