import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";

const LanguageSwitch = ({ closeMenu }) => {
  const i18n = useSelector((reduxStore) => reduxStore.i18n);

  return (
    <div className="language-switch" onClick={closeMenu ? closeMenu : null}>
      {i18n.translations?.length > 1 &&
        <>
          {i18n.translations.map((translation) => (
            <a
              key={translation.language.id}
              href={translation.url.path}
              className={classNames({
                "body-m": true,
                "active make-headline-light": i18n.currentLanguage === translation.language.id,
                //"not-translated": !translation.path,
              })}
            >
              {translation.language.id.toUpperCase()}
            </a>
          ))}
        </>
      }
    </div>
  );
};

LanguageSwitch.propTypes = {
  closeMenu: PropTypes.func,
};

export default LanguageSwitch;
