import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { FormattedMessage, useIntl } from "react-intl";
import {festivalDayOffset} from "@js/lib/festival-day-offset";
/**
 * Formats the date to show the festival day.
 * Festival days are 3 hours behind the normal days.
 *
 * @param {String|Number} date Timestamp of the date to be formatted
 * @returns
 */
const FestivalDay = ({ date }) => {
  const [offset, offsetUnit] = festivalDayOffset;

  const intl = useIntl();
  const longDayFormat = intl.formatMessage({ id: "date.long_day_format" });

  const normalDay = moment.unix(date).format(longDayFormat);
  const festivalDay = moment.unix(date).add(offset, offsetUnit).format(longDayFormat);

  if (normalDay === festivalDay) {
    return null;
  }

  return (
    <span className="festival-day">
      <FormattedMessage id="festival_day" />
      {": "}{festivalDay}
    </span>
  );
};

FestivalDay.propTypes = {
  /**
   * Timestamp of the date to be formatted.
   */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FestivalDay;
