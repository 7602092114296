import React, { useEffect, useRef } from "react";
import useOverviewContext from "../../store/use-overview-context";

/**
 * Component for implementing endless scrolling behavior.
 * This component will automatically increase the currentPage state when the user scrolls
 * to the bottom of the page, which then will trigger the a refetch.
 * Place it below the list of nodes in the overview component.
 * @returns {JSX.Element} The EndlessScroll component.
 */
const EndlessScroll = () => {
  // Get relevant values and functions from the OverviewContext.
  const { setPageMode, metaData, nodes, loading, fetchMore } =
    useOverviewContext();

  const scrollRef = useRef();

  const fetchMoreResults = () => {
    const newPage = Math.ceil(nodes.length / metaData.perPage);

    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          entityById: {
            ...prev.entityById,
            executable: {
              ...prev.entityById?.executable,
              execute: {
                total_rows: fetchMoreResult.entityById.executable.execute.total_rows,
                rows: [
                  ...(prev.entityById?.executable?.execute?.rows || []),
                  ...(fetchMoreResult.entityById?.executable?.execute?.rows ||
                    []),
                ],
              },
            },
          },
        });
      },
      variables: { page: newPage },
    });
  };

  useEffect(() => {
    // Set the page mode to endless-scroll for correct fetching.
    setPageMode("endless-scroll");

    const triggerElement = scrollRef.current;

    // Check if the element is intersecting with the viewport.
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.isIntersecting &&
          !loading && // Prevent fetching when loading.
          nodes.length < metaData.totalRows && // Prevent fetching when all nodes are fetched.
          metaData.perPage !== 0
        ) {
          fetchMoreResults();
        }
      },
      { threshold: 0, rootMargin: "6000px" }
    );

    if (triggerElement) {
      observer.observe(triggerElement);
    }

    // Clean up function.
    return () => {
      if (triggerElement) {
        observer.disconnect();
      }
    };
  });

  return <div ref={scrollRef} />;
};

export default EndlessScroll;
