import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Error500 from "@system/error-500";
import Error404 from "@system/error-404";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import usePageTransitionContext from "@js/page-transition/use-page-transition-context";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import ScrollRestoration from "./scroll-restoration";

const NodeWrapper = ({ entity, loading, error, children }) => {
  const { setIsLoading, isLoading } = usePageTransitionContext(),
    mainSettings = useSelector((reduxStore) => reduxStore.appStore.mainSettings),
    location = useLocation();

  // Functionality of the "Real" Loading animation. An Overlay is shown as long as the query is taking to load.
  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading]);

  // Return the loading indicator as long as query is not completed.
  if (loading) {
    return false; // or you can return a loading spinner or indicator here
  }

  // Return the 500 error component if query was not successful.
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty.
  if (!entity) {
    return <Error404 status={404} />;
  }

  const published = entity.status ? "node-published" : "node-unpublished";

  const nodeClassNames = classNames({
    [`node-${entity.entityBundle} node-full-page ${published}`]: true,
    "detail-page":
      entity.entityBundle === "event" || entity.entityBundle === "participant",
    "festival-overview-page":
      location &&
      mainSettings?.fieldFestivalOverviewPage?.uri.path === location?.pathname,
    "conference-overview-page":
      location &&
      mainSettings?.fieldConferenceOverviewPage?.uri.path ===
        location?.pathname,
  });

  return (
    <motion.article
      className={nodeClassNames}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ScrollRestoration />
      <Helmet>
        <title>Reeperbahn Festival | {entity.label}</title>
      </Helmet>
      <div>{children}</div>
    </motion.article>
  );
};

NodeWrapper.propTypes = {
  entity: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.node,
};

export default NodeWrapper;
