import React from "react";
import PropTypes from "prop-types";

import ErrorBoundary from "../../error-boundary";
import ParagraphSwitch from "./paragraph-switch";

const ContentBase = (props) => {
  return (
    <section className={`content-wrap ${props.className}`} data-viewmode={props.viewMode}>
      {props.content.map((item, index) => (
        <ErrorBoundary key={index}>
          {
            // eslint-disable-next-line complexity
            (() => {
              let paragraphItem = item?.entity ? item?.entity : item;

              if (paragraphItem?.entityBundle === "from_library") {
                paragraphItem =
                  item?.entity.fieldReusableParagraphRawField.first.entity
                    .paragraphsRawField.first.entity;
              }

              return (
                <ParagraphSwitch
                  paragraphItem={paragraphItem}
                  nodeContent={props.nodeContent}
                  viewMode={props.viewMode}
                />
              );
            })()
          }
        </ErrorBoundary>
      ))}
    </section>
  );
};

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
  viewMode: PropTypes.string,
};

export default ContentBase;
