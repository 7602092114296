import React, { useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Image from "../../general-components/image/image";
import classNames from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";
import ColorMask from "@js/participants/components/color-mask";
import CustomHyphenation from "@general-components/custom-hyphenation";
import { generateSpeakerName } from "@lib/generate-speaker-name";

const colorSchemes = [
  {
    name: "scheme-1",
    titleColor: "theme-4",
    blurColor: "theme-3",
  },
  {
    name: "scheme-2",
    titleColor: "theme-1",
    blurColor: "theme-5",
  },
  {
    name: "scheme-3",
    titleColor: "theme-3",
    blurColor: "theme-1",
  },
];

const DetailPageIntro = ({ content, mode }) => {
  const randomColorScheme = useMemo(() => {
    if (content.fieldImage) {
      return colorSchemes[Math.floor(Math.random() * colorSchemes.length)];
    } else {
      return colorSchemes[Math.floor(Math.random() * (2 - 1 + 1) + 1)];
    }
  }, []);

  const detailPageIntro = useRef(null);

  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [100, 500], [1, 0]);
  const filter = useTransform(scrollY, [100, 250], ["blur(0px)", "blur(20px)"]);

  const introClassNames = classNames({
    "detail-page-intro": true,
    [mode]: true,
    "with-image": content?.fieldImage,
    "without-image": !content?.fieldImage,
  });

  let label = content.label;

  if (mode === "speaker") {
    label = generateSpeakerName(content);
  }

  let titleLength = content.label?.replace(/&shy;/g, "").length;

  const titleClassNames = classNames({
    title: true,
    "headline-xxl": titleLength <= 20,
    "headline-xl": titleLength > 20,
    [`color-${randomColorScheme?.titleColor}`]: content.fieldImage,
    container: content.entityBundle === "event",
  });

  const subtitleClassNames = classNames({
    "headline-s title": true,
    [`color-${randomColorScheme?.titleColor}`]: content.fieldImage,
  });

  const imageWrapperClassNames = classNames({
    "col-16 col-lg-10 offset-lg-3 col-xl-8 offset-xl-4":
      mode === "act" || mode === "event",
    "col-16 col-lg-8": mode === "speaker",
  });

  return (
    <div
      className={introClassNames}
      data-color-scheme={randomColorScheme?.name}
      ref={detailPageIntro}
    >
      {content.fieldImage ? (
        <motion.div
          style={{
            opacity,
            filter,
          }}
          className="scroll-away-wrapper"
        >
          <div className="container-fluid no-padding">
            <div className="row">
              <div className={imageWrapperClassNames}>
                <Image
                  data={content.fieldImage?.fieldMediaImage}
                  nodeTitle={content.label}
                  style={content.fieldImage?.fieldMediaImage?.styleSpeaker}
                />
              </div>
            </div>
          </div>
          {mode !== "speaker" && (
            <ColorMask
              type={"act-detail"}
              className={randomColorScheme?.blurColor}
            />
          )}
        </motion.div>
      ) : (
        <div
          className="intro-color-wrapper"
          data-color={randomColorScheme?.blurColor}
        />
      )}

      {label && (
        <div className="title-wrapper container">
          <div className="main-title row">
            <h1 className={titleClassNames}>
              {/* span used for styling purposes in combination with spotify toggle. */}
              <span>
                <CustomHyphenation length={9}>{label}</CustomHyphenation>
              </span>
            </h1>

            {mode === "speaker" && !!content.fieldCountry?.list?.length && (
              <>
                {content.fieldCountry.list.map((country) => (
                  <div className="tag body-s" key={country}>
                    {country.value}
                  </div>
                ))}
              </>
            )}
          </div>

          {content.fieldSubline && (
            <div className="subtitle container">
              <h2 className={subtitleClassNames}>{content.fieldSubline}</h2>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const detailPageIntroPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

DetailPageIntro.propTypes = {
  content: detailPageIntroPropTypes,
};

export default DetailPageIntro;
