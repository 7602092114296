import { useEffect } from "react";
import { useSessionStorage } from "react-use";
import { useLocation } from "react-router-dom";

const ScrollRestoration = () => {
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useSessionStorage(
    location.key,
    0
  );

  useEffect(() => {
    window.scrollTo({ top: scrollPosition });
    const storeScrollPosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", storeScrollPosition, { passive: true });
    return () => {
      window.removeEventListener("scroll", storeScrollPosition);
    };
  }, []);

  return null;
};

ScrollRestoration.propTypes = {};

export default ScrollRestoration;
