import React from "react";
import PropTypes from "prop-types";
import TeaserActMasked from "@teasers/act/teaser-act-masked";
import InteractiveMarquee from "./interactive-marguee";
import {useSelector} from "react-redux";

const ActsCarouselSwiper = ({ nodes }) => {

  const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  const preparedNodes = nodes &&
    nodes.map((item, index) => (
      <button  key={index} draggable="false">
        <TeaserActMasked
          item={item}
          index={index}
          forceMask={index - 1}
        />
      </button>
    ));


  return (
    <div className="marquee-wrapper">
      {/* https://codesandbox.io/s/infinite-marquee-with-framer-motion-wheel-scroll-d78he6?file=/src/App.tsx */}
      {isMobile ? (
        <div className="item">
          {preparedNodes}
        </div>
      ) : (
        <InteractiveMarquee>
          {preparedNodes}
        </InteractiveMarquee>
      )}
    </div>
  );
};

ActsCarouselSwiper.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      fieldImage: PropTypes.object,
      fieldLink: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.shape({
          routed: PropTypes.bool,
          path: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
};

export default ActsCarouselSwiper;
