import React from "react";
import PropTypes from "prop-types";

import TeaserAct from "./act/teaser-act";

const TeaserParticipantSwitch = (props) => {
  /**
   * TEMPORARY "BRIDGING"
   * fieldParticipantType can be a string or an array.
   * If the fieldParticipantType is a string, convert it to an array.
   * @see https://office.e-fork.net/issues/15549
   */
  let participantType = props.item.fieldParticipantType;
  if (typeof participantType === "string") {
    participantType = [props.item.fieldParticipantType];
  }

  if (participantType.includes("act")) {
    return <TeaserAct item={props.item} pagerFullPage={props.pagerFullPage} />;
  }

  return null;
};

TeaserParticipantSwitch.propTypes = {
  item: PropTypes.object.isRequired,
  viewMode: PropTypes.string,
};

export default TeaserParticipantSwitch;
