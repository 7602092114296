/**
 * Scroll to function with callback. Returns a promise that resolves when the scroll is complete.
 * Idea from @see https://stackoverflow.com/a/68048095
 * @param {Object} options window.scrollTo options
 * @returns {Promise<void>}
 */
const scrollTo = (options) => {
  window.scrollTo(options);

  return new Promise((resolve) => {
    const scrollHandler = () => {
      if (Math.floor(window.pageYOffset) === Math.floor(options.top)) {
        window.removeEventListener("scroll", scrollHandler);
        resolve();
      }
    };
    window.addEventListener("scroll", scrollHandler, { passive: true });
  });
};

export default scrollTo;
