import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import { LinkPropType } from "../../../link/link";
import LazyLoad from "react-lazyload";
import Marquee from "react-fast-marquee";

const ParagraphLogosPartners = ({ content }) => {
  const [speed, setSpeed] = useState(40 + Math.floor(Math.random() * 20));

  const sectionClassNames = classNames({
    "paragraph paragraph-logos-partners": true,
    small: content.fieldSmall,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      {content.fieldHeading &&
        <div className="container">
          <div className="row">
            <div className="col-16">
              <div className="heading body-m">{content.fieldHeading}</div>
            </div>
          </div>
        </div>
      }

      <div className="marquee-wrapper">
        <Marquee
          pauseOnHover={false}
          pauseOnClick={false}
          speed={speed}
          gradient={false}
        >
          {[...Array(8)].map((elem, index) => (
            <div key={index} className="marquee-element">
              {content.fieldLogosPartners.map((item, subIndex) => (
                <div
                  key={subIndex}
                  className={classNames({
                    partner: true,
                  })}
                >
                  {item.fieldLink !== null ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.fieldLink?.uri?.path}
                    >
                      <LazyLoad offset={500}>
                        <Image data={item.fieldMediaImage} />
                      </LazyLoad>
                    </a>
                  ) : (
                    <ErrorBoundary>
                      <LazyLoad offset={500}>
                        <Image data={item.fieldMediaImage} />
                      </LazyLoad>
                    </ErrorBoundary>
                  )}
                </div>
              ))}
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};

ParagraphLogosPartners.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLogosPartners: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLink: LinkPropType,
        fieldMediaImage: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string,
          }).isRequired,
          styleFullPage: null,
          desktop: null,
          mobile: null,
        }),
      })
    ),
  }),
};

export default ParagraphLogosPartners;
