import React from "react";
import AppearanceDetailed from "@nodes/shared-components/appearances/appearance-detailed";
import AppearanceSimple from "@nodes/shared-components/appearances/appearance-simple";



const sortByDate = (arr) => {
  // Create a shallow copy of the array before sorting to avoid modifying the original array
  // Remove any falsy values from the array (e.g. null, undefined, empty strings)
  const arrCopy = [...(arr.filter(Boolean))];

  return arrCopy.sort((a, b) => {
    // Convert the "value" fields to numbers (they are in UNIX timestamp format)
    const dateA = parseInt(a.fieldDateRawField?.first.value, 10);
    const dateB = parseInt(b.fieldDateRawField?.first.value, 10);

    // Sort in ascending order
    return dateA - dateB;
  });
}

const Appearances = ({ content, type, showAddToCalendar, viewMode, detailedMode, showEndDate, forceLongDayName }) => {
  const sortedAppearances = sortByDate(content.fieldAppearances);

  return (
    <div className="appearances">
      {sortedAppearances?.map((appearance, appearanceId) => {
        if (appearance) {
          return (
            <React.Fragment key={appearanceId}>
              {detailedMode ? (
                <AppearanceDetailed
                  appearance={appearance}
                  type={type}
                  showAddToCalendar={showAddToCalendar}
                  showEndDate={showEndDate}
                  viewMode={viewMode}
                  appearanceId={appearanceId}
                  content={content}
                  forceLongDayName={forceLongDayName}
                />
              ) : (
                <AppearanceSimple
                  appearance={appearance}
                  type={type}
                  showAddToCalendar={showAddToCalendar}
                  showEndDate={showEndDate}
                  viewMode={viewMode}
                  appearanceId={appearanceId}
                  content={content}
                  forceLongDayName={forceLongDayName}
                />
              )}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default Appearances;
