import React from "react";
import { useSelector } from "react-redux";
import FieldTime from "@general-components/date-time/field-time";
import DateWrapper from "@js/participants/components/date-wrapper";
import { FormattedMessage, useIntl } from "react-intl";
import FestivalDay from "@general-components/date-time/festival-day";

const AppearanceDetailed = ({
  appearance,
  type,
  showAddToCalendar,
  viewMode,
  forceLongDayName,
  showEndDate,
}) => {
  const releaseState = useSelector(
    (reduxStore) => reduxStore.appStore.releaseState
  );

  if (!appearance.fieldPlanned) {
    forceLongDayName = true;
  }

  const intl = useIntl();
  const shortDayFormat = intl.formatMessage({ id: "date.short_day_format" });
  const longDayFormat = intl.formatMessage({ id: "date.long_day_format" });

  return (
    <article className="appearance simple">
      <div className="row">
        <div className="time">
          <div className="day body-m make-headline-light">
            {releaseState.showDay && (
              <FieldTime
                timestamp={true}
                date={appearance.fieldDateRawField.first.value}
                format={
                  !releaseState.showDate || forceLongDayName ? longDayFormat : shortDayFormat
                }
                dependsOnReleaseState={true}
              />
            )}
          </div>
          <div className="time body-m">
            <DateWrapper
              content={appearance}
              viewMode={"time"}
              showEndDate={showEndDate}
            />
          </div>
        </div>
        {appearance.fieldVenueLocation && releaseState.showDate && (
          <div className="venue d-flex flex-row icon-flex-wrap make-headline">
            <span className="venue-name body-m make-headline">
              {appearance.fieldVenueLocation.label}
            </span>
          </div>
        )}
      </div>
      <div className="row">
        <div className="festival-day body-m">
          {releaseState.showDay && (
            <FestivalDay
              date={appearance.fieldDateRawField.first.value}
              dependsOnReleaseState={true}
            />
          )}
        </div>
      </div>
    </article>
  );
};

export default AppearanceDetailed;
