import React from "react";
import { useSelector } from "react-redux";
import FieldTime from "@general-components/date-time/field-time";
import DateWrapper from "@js/participants/components/date-wrapper";
import LocationPinBlack from "@/assets/ui/location-pin-black.svg";
import { Link } from "react-router-dom";
import { AddToCalendar } from "@general-components/add-to-calendar";
import { encodeUrl } from "@lib/encode-url";
import { FormattedMessage, useIntl } from "react-intl";
import FestivalDay from "@general-components/date-time/festival-day";

const AppearanceDetailed = ({
  content,
  appearance,
  type,
  showAddToCalendar,
  viewMode,
  appearanceId,
  showEndDate,
}) => {
  const releaseState = useSelector(
    (reduxStore) => reduxStore.appStore.releaseState
  );
  const mainSettings = useSelector(
    (reduxStore) => reduxStore.appStore.mainSettings
  );
  
  const intl = useIntl();
  const longDayFormat = intl.formatMessage({ id: "date.long_day_format" });

  return (
    <article className="appearance detailed">
      <div className="container no-padding">
        <div className="row">
          {(type === "event" || type === "act") && (
            <div
              className={`col type body-m make-headline-light ${
                appearanceId === 0 ? "" : "d-none d-lg-flex"
              }`}
            >
              {/* Only show label on first occasion */}
              {appearanceId === 0 && (
                <>
                  {/* All Acts are Concerts */}
                  {type === "act" && (
                    <>{content.fieldParticipantCategory?.name}</>
                  )}

                  {/* Events are having different types */}
                  {type === "event" && content?.fieldEventType && (
                    <>{content.fieldEventType?.name}</>
                  )}
                </>
              )}
            </div>
          )}
          {type === "speaker" && appearance.fieldHappening && (
            <Link
              to={appearance.fieldHappening?.url.path}
              className="col event-name body-m make-headline-light"
            >
              {appearance.fieldHappening.label}
            </Link>
          )}

          <div className="col time">
            {releaseState.showDay && (
              <>
                <div className="d-flex time body-m">
                  <div className="d-flex align-items-start make-headline-light">
                    <FieldTime
                      timestamp={true}
                      date={appearance.fieldDateRawField.first.value}
                      format={longDayFormat}
                      dependsOnReleaseState={true}
                    />
                  </div>
                  <DateWrapper
                    content={appearance}
                    viewMode={"time"}
                    showEndDate={showEndDate}
                  />
                </div>
                <div className="festival-day body-m">
                  {releaseState.showDay && (
                    <FestivalDay
                      date={appearance.fieldDateRawField.first.value}
                      dependsOnReleaseState={true}
                    />
                  )}
                </div>
              </>
            )}
          </div>

          <div className="col venue d-flex flex-row icon-flex-wrap">
            <>
              <img
                alt={"Location Pin"}
                className={"icon location-pin black"}
                src={LocationPinBlack}
              />
              {releaseState.showDate && appearance.fieldVenueLocation ? (
                <>
                  {appearance.fieldVenueLocation &&
                  viewMode === "detail" &&
                  mainSettings.fieldVenueOverviewPage ? (
                    <Link
                      to={`${
                        mainSettings.fieldVenueOverviewPage.uri.path
                      }#${encodeUrl(appearance.fieldVenueLocation.label)}`}
                      className="venue-name body-m action-element"
                    >
                      {appearance.fieldVenueLocation.label}
                    </Link>
                  ) : (
                    <span className="venue-name body-m">
                      {appearance.fieldVenueLocation.label}
                    </span>
                  )}
                </>
              ) : (
                <span>TBA</span>
              )}
            </>
          </div>
          {showAddToCalendar && releaseState.showDate && (
            <div className="col atc d-flex flex-row icon-flex-wrap">
              {showAddToCalendar && (
                <AddToCalendar content={content} appearance={appearance} />
              )}
            </div>
          )}
        </div>

        {type === "linked-event" && (
          <Link
            to={appearance.fieldHappening?.url.path}
            className="col name d-flex body-m make-headline-light"
          >
            {appearance.fieldHappening?.label}
          </Link>
        )}
      </div>
    </article>
  );
};

export default AppearanceDetailed;
