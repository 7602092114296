import React from "react";
import PropTypes from "prop-types";

export const SocialMedia = ({ content }) => {
  return (
    <div className="col-16 social-media">
      <nav>
        <ul className="row">
          {content.fieldFacebook && (
            <li className="col-16 col-lg-8">
              <a
                className="facebook"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldFacebook.uri.path}
              >
                Facebook
              </a>
            </li>

          )}
          {content.fieldYoutube && (
            <li className="col-16 col-lg-8">
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldYoutube.uri.path}
              >
                YouTube
              </a>
            </li>
          )}
          {content.fieldFlickr && (
            <li className="col-16 col-lg-8">
                <a
                className="flickr"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldFlickr.uri.path}
              >
                Flickr
              </a>
            </li>
          )}
          {content.fieldInstagram && (
            <li className="col-16 col-lg-8">
              <a
                className="instagram"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldInstagram.uri.path}
              >
                Instagram
              </a>
            </li>

          )}
          {content.fieldVimeo && (
            <li className="col-16 col-lg-8">
              <a
                className="vimeo"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldVimeo.uri.path}
              >
                Vimeo
              </a>
            </li>

          )}
          {content.fieldLinkedin && (
            <li className="col-16 col-lg-8">
              <a
                className="linkedin"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldLinkedin.uri.path}
              >
                LinkedIn
              </a>
            </li>

          )}
          {content.fieldPinterest && (
            <li className="col-16 col-lg-8">
              <a
                className="pinterest"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldPinterest.uri.path}
              >
                Pinterest
              </a>
            </li>

          )}
          {content.fieldX && (
            <li className="col-16 col-lg-8">
              <a
                className="x"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldX.uri.path}
              >
                X
              </a>
            </li>

          )}
          {content.fieldXing && (
            <li className="col-16 col-lg-8">
              <a
                className="xing"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldXing.uri.path}
              >
                Xing
              </a>
            </li>

          )}
          {content.fieldTumblr && (
            <li className="col-16 col-lg-8">
              <a
                className="tumblr"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldTumblr.uri.path}
              >
                Tumblr
              </a>
            </li>

          )}
          {content.fieldSpotify && (
            <li className="col-16 col-lg-8">
              <a
                className="spotify"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldSpotify.uri.path}
              >
                Spotify
              </a>
            </li>
          )}
          {content.fieldTiktok && (
            <li className="col-16 col-lg-8">
              <a
                className="tiktok"
                target="_blank"
                rel="noopener noreferrer"
                href={content.fieldTiktok.uri.path}
              >
                TikTok
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

SocialMedia.propTypes = {
  content: PropTypes.shape({
    fieldFacebook: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldX: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};
