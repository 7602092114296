import React, { Suspense, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import LazyLoad from "react-lazyload";
import EditButton from "../../../backend/edit-button";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { encodeUrl } from "../../../lib/encode-url";
import { calculateCollapseHeight } from "../../../lib/calculate-collape-height";
import { isIOS, isMacOs, isMobile } from "react-device-detect";
import classNames from "classnames";
import { getMapsLink } from "../../../lib/get-maps-link";
import AccessibilityIcons from "@general-components/accessibility-icons";

const openLocationLink = ({ name, address, geo, parentLocation, fallback }) => {
  //@todo: Implement function to let the user set a forced gmaps link
  /*if (!!fallback) {
    return window.open(fallback, "_blank");
  }*/

  if (isIOS || isMacOs) {
    return window.open(
      getMapsLink(name, address, geo, { platform: "apple" }, parentLocation),
      "_blank"
    );
  }

  if (isMobile) {
    return window.open(
      getMapsLink(name, address, geo, { platform: "android" }, parentLocation),
      "_blank"
    );
  }
  return window.open(getMapsLink(name, address, geo, {}, parentLocation), "_blank");
};

const TeaserTags = (props) => {
  return (
    <div className="tag-wrapper">
      {props.properties &&
        props.properties.map((property) => (
          <div className="tag body-m" key={property.id}>
            {property.name}
          </div>
        ))}
      {props.capacity && props.capacity > 0 && props.capacity !== "0" && (
        <div className="tag body-m">{props.capacity}P</div>
      )}
      <AccessibilityIcons
        content={props.accessibility}
      />
    </div>
  );
};

TeaserTags.propTypes = {
  properties: PropTypes.array,
  capacity: PropTypes.number,
};

const TeaserVenue = ({ item, updateUrl, index }) => {
  const teaser = useRef();
  const location = useLocation();

  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  const preExpand = (() => {
    if (item && encodeUrl(item.label) === location?.hash.replace("#", "")) {
      return [item.id];
    }
    return [];
  })()

  const updateAccordion = () => {
    if (updateUrl && teaser.current) {
      let scrollPosition =
        teaser.current.getBoundingClientRect().top + window.scrollY - 150;

      history.pushState(
        null,
        window.location.pathname,
        `#${encodeUrl(item.label)}`
      );

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Timeout is only set because the "todoerror" below forces a re rendering, can be removed after error is fixed
    setTimeout(() => {
      calculateCollapseHeight(teaser.current);
    }, 500);

    if (item && encodeUrl(item.label) === location.hash.replace("#", "")) {
      setTimeout(() => {
        updateAccordion();
      }, 500);
    }
  }, []);

  const teaserClassNames = classNames({
    "node node-teaser teaser-venue venue-element": true,
    "with-image": item.fieldImage,
    "no-image": !item.fieldImage,
  });

  return (
    <article
      className={teaserClassNames}
      ref={teaser}
      id={encodeUrl(item.label)}
      data-to-scrollspy-id={encodeUrl(item.label)}
      data-teaser-index={index}
    >
      <Suspense fallback={<div></div>}>
        <Accordion
          allowZeroExpanded={true}
          onChange={() => updateAccordion()}
          preExpanded={preExpand}
        >
          <AccordionItem uuid={item.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {item.fieldImage && isMobile && (
                  <div className="image">
                    <LazyLoad offset={1000}>
                      {item.fieldImage && (
                        <Image
                          data={item.fieldImage.fieldMediaImage}
                          credit={item.fieldImage.fieldCredits}
                          nodeTitle={item.label}
                        />
                      )}
                    </LazyLoad>
                  </div>
                )}
                <div className="name-wrapper infos">
                  {item.label && <h2 className="name">{item.label}</h2>}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="inner-wrapper content-wrapper">
                {(!!item.fieldLocationProperties || !!item.fieldCapacity || !!item.fieldAccessibility) && (
                  <TeaserTags
                    properties={item.fieldLocationProperties}
                    capacity={!!item.fieldCapacity && item.fieldCapacity}
                    accessibility={!!item.fieldAccesibility && item.fieldAccesibility}
                  />
                )}
                {item.description && (
                  <div
                    className="teaser text"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                )}
                {item.fieldAddress && (
                  <div className="address-wrapper">
                    <address>
                      {item.fieldAddress.addressLine1 && (
                        <span className="street">
                          {item.fieldAddress.addressLine1}
                        </span>
                      )}
                      {item.fieldAddress.postalCode &&
                        item.fieldAddress.locality && (
                          <div className="city-wrapper">
                            {item.fieldAddress.postalCode && (
                              <span className="postal-code">
                                {item.fieldAddress.postalCode}
                              </span>
                            )}
                            {item.fieldAddress.locality && (
                              <span className="locality">
                                {item.fieldAddress.locality}
                              </span>
                            )}
                          </div>
                        )}
                    </address>
                    <a
                      className="maps-link"
                      onClick={() =>
                        openLocationLink({
                          name: item.label,
                          address: item.fieldAddress,
                          geo: {
                            lat: item.fieldGeolocation?.lat,
                            lng: item.fieldGeolocation?.lng,
                          },
                          parentLocation: item.fieldParentLocation
                        })
                      }
                    >
                      Find me on Maps
                    </a>
                  </div>
                )}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Suspense>
    </article>
  );
};

export const teaserVenuePropTypes = PropTypes.shape({
  id: PropTypes.string,
  activeBefore: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserVenue.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserVenuePropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserVenue;
