import React, { useRef, useState } from "react";
import Image from "../general-components/image/image";
import ErrorBoundary from "../error-boundary";
import classNames from "classnames";
import Video from "../general-components/content-base/paragraphs/video/components/video";
import PropTypes from "prop-types";

const ParagraphMediaSwitch = ({ props, media, item, autoplay, forcePlay, loop, controls, inStack }) => {
  return (
    <>
      {media.entityBundle === "image" && (
        <ErrorBoundary>
          <article
            className={`image ${
              media.fieldMediaImage.style.height /
              media.fieldMediaImage.style.width >
              1.2
                ? "portrait"
                : "landscape"
            }`}
          >
            <Image data={media.fieldMediaImage} />
          </article>
          {(item?.customCaption ||
            media.fieldCaption ||
            media.fieldCredit) && (
            <div className="info-wrap">
              {(item?.customCaption || media.fieldCaption) && (
                <span className="caption">
                  {item?.customCaption || media.fieldCaption}
                </span>
              )}
              {media.fieldCredits && (
                <span className="credit">
                  Copyright: {media.fieldCredits}
                </span>
              )}
            </div>
          )}
        </ErrorBoundary>
      )}

      {(media.entityBundle === "embedded_video" ||
        media.entityBundle === "video") && (
        <ErrorBoundary>
          <Video
            content={media}
            autoplay={autoplay}
            forcePlay={forcePlay}
            loop={loop}
            controls={controls}
            inStack={inStack}
          />
          <div
            className={classNames({
              "caption-wrap": true,
            })}
          >
            {item?.customCaption && (
              <span className="caption">
                {item.customCaption}
              </span>
            )}
          </div>
        </ErrorBoundary>
      )}
    </>
  );
};

ParagraphMediaSwitch.propTypes = {
  media: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        customCaption: PropTypes.string,
        defaultCaption: PropTypes.string,
        entity: PropTypes.shape({
          entityBundle: PropTypes.string,
          fieldCaption: PropTypes.string,
          fieldCredits: PropTypes.string,
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string,
              width: PropTypes.number,
              height: PropTypes.number,
            }),
          }),
        }),
      })
    ),
  }),
  autoplay: PropTypes.bool,
};

export default ParagraphMediaSwitch;