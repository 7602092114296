import React from "react";
import useOverviewContext from "../../store/use-overview-context";
import { FormattedMessage } from "react-intl";

const NoResults = ({ overviewType }) => {
  const { metaData, loading, error } = useOverviewContext();

  if (metaData.totalRows !== 0 || loading || error && overviewType !== "overview_acts") return null;

  return (
    <div className="container">
      <div className="row">
        <div className="col-16 col-md-8 offset-md-1">
          <FormattedMessage id="overview.no_results" />
        </div>
      </div>
    </div>
  );
};

export default NoResults;
