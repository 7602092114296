import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";
import BusinessWrapper from "@js/participants/components/business-wrapper";
import { FormattedMessage } from "react-intl";
import { generateSpeakerName } from "@lib/generate-speaker-name";

const TeaserSpeakerShort = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  if (item) {
    return (
      <article
        className="node node-teaser teaser-speaker short"
        data-index={index}
      >
        <Link to={item.url?.path || item.path.alias}>
          <div className="mask-wrapper">
            <EditButton
              adminApp={adminApp}
              entityId={item?.id}
              destinationRoute={location.pathname}
            />
            <div className="row">
              <div className="col-8 image">
                {item?.fieldImage && (
                  <Image
                    data={item?.fieldImage?.fieldMediaImage}
                    credit={item.fieldImage.fieldCredits}
                    nodeTitle={item?.title}
                  />
                )}
              </div>
              <div className="col-8 infos">
                <div className="title-wrapper">
                  {item.title && (
                    <h3 className="name headline-s">
                      {generateSpeakerName(item)}
                      {item.fieldCountry && item.fieldCountry?.list.length > 0 &&
                        <>
                          {" "}({!!item.fieldCountry?.list?.length &&
                          item.fieldCountry.list
                            .map((country) => country.value)
                            .join(", ")})
                        </>
                      }
                    </h3>
                  )}
                </div>
                <BusinessWrapper content={item} />
                <span className="action-element body-m">
                  <FormattedMessage id="learn_more" />
                </span>
              </div>
            </div>
          </div>
        </Link>
      </article>
    );
  }
};

export const teaserSpeakerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserSpeakerShort.propTypes = {
  item: teaserSpeakerPropTypes,
};

export default TeaserSpeakerShort;
