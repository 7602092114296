import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RBXAnimation from "./rbx-animation/rbx-animation";
import { motion, AnimatePresence } from "framer-motion";
import usePageTransitionContext from "@js/page-transition/use-page-transition-context";
import RBXLogo from "@/assets/rbx-company/RBX-Logo-white.svg";
import {useSelector} from "react-redux";

const LoadingIndicator = ({ fullPage = false, overlay = false, delay = 0 }) => {
  const { isLoading } = usePageTransitionContext();
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  if (overlay) {
    return (
      <div
        className={"loading-indicator-wrap overlay"}
      >
        <AnimatePresence>
          {isLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <RBXAnimation className="loading-animation" scalingFactor={2} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  } else {
    return (
      <div
        className={`loading-indicator-wrap ${
          fullPage ? "full-page" : "normal"
        } ${overlay ? "overlay" : ""}`}
      >
        {!microSite ? (
          <RBXAnimation className="loading-animation" scalingFactor={2} />
        ) : (
          <img
            src={RBXLogo}
            className="rbx-logo"
            alt={"Logo RBX"}
          />
        )}
      </div>
    );
  }
};

LoadingIndicator.propTypes = {
  fullPage: PropTypes.bool,
  overlay: PropTypes.bool,
  /**
   * Delay in ms before the loading indicator is shown.
   */
  delay: PropTypes.number,
};

export default LoadingIndicator;
