import React from "react";
import PropTypes from "prop-types";

import AccessibilityIcon from "../../assets/ui/accessibility-logo.svg";
import { Menu } from "../general-components/navigation/menu";
import { useDispatch, useSelector } from "react-redux";
import {
  accessibilityMenuOpenAction,
  mainMenuOpenAction,
} from "../app-actions";

/**
 * Close on menu link click.
 */
const AccessibilityMenu = () => {
  const dispatch = useDispatch();

  const accessibilityMenuOpen = useSelector(
    (reduxStore) => reduxStore.appStore.accessibilityMenuOpen
  );
  const mainMenuOpen = useSelector(
    (reduxStore) => reduxStore.appStore.mainMenuOpen
  );

  const toggleAccessibilityMenu = () => {
    dispatch(accessibilityMenuOpenAction(!accessibilityMenuOpen));
    dispatch(mainMenuOpenAction(false));
  };

  return (
    <div
      className={`accessibility-menu-wrapper ${
        accessibilityMenuOpen ? "active" : "inactive"
      }`}
    >
      <button
        className={"accessibility-button"}
        onClick={() => toggleAccessibilityMenu()}
        aria-label="open accessibility menu"
        tabIndex={0}
      >
        <img
          src={AccessibilityIcon}
          alt={"Button: Einstellungen zur Barrierearmut"}
        />
      </button>
      <div className="fixed-wrapper header-element accessibility">
        <div className="container">
          <div className="row">
            <div className="col-16">
              <Menu
                navClassName="main-menu-desktop"
                menuName="ACCESSIBILITY_MENU"
                ariaLabel={"Accessibility Menu"}
                showBorderElement={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccessibilityMenu.propTypes = {
  accessibilityMenuOpen: PropTypes.bool,
  accessibilityMenu: PropTypes.object,
};

export default AccessibilityMenu;
