import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DossierElement from "./components/dossier-element";
import Slider from "react-slick";

/**
 * Maximum of three news or person entries.
 *
 * @todo Events.
 */
const ParagraphDossier = ({ content }) => {
  const sectionClassNames = classNames({
      "paragraph paragraph-dossier": true,
    }),
    [slideIndex, setSlideIndex] = useState(0);

  const sliderSettings = {
    adaptiveHeight: false,
    arrows: true,
    fade: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchMove: true,
    centerMode: false,
    infinite: false,
    beforeChange: (oldIndex, newIndex) => {
      setSlideIndex(newIndex);
    },
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 3,
          touchMove: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.5,
          touchMove: true,
        },
      },
    ],
  };

  const paragraphs =
    content.fieldParagraphs?.filter((paragraph) => paragraph?.status) || [];

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h2>{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      <Slider {...sliderSettings}>
        {paragraphs.map((item, index) => (
          <React.Fragment key={index}>
            <DossierElement content={item} active={slideIndex === index} />
          </React.Fragment>
        ))}
      </Slider>
    </section>
  );
};

ParagraphDossier.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    fieldParagraphs: PropTypes.array,
  }),
};

export default ParagraphDossier;
