import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {restHostBackend} from "../../config";

/**
 * Use this component for rendering images queried from Drupal.
 */
class Image extends Component {
  render() {
    if (this.props.data) {

      let { alt, title } = this.props.data;

      if (!alt && this.props.caption) {
        alt = this.props.caption;
      } else if (!alt && this.props.nodeTitle) {
        alt = this.props.nodeTitle;
      }

      if (!title && this.props.caption) {
        title = this.props.caption;
      } else if (!title && this.props.nodeTitle) {
        title = this.props.nodeTitle;
      }

      if (this.props.fullPage) {
        return (
          <div className="image-caption-wrapper">
            <img
              className={this.props.className}
              src={this.props.data.styleFullPage.url}
              title={title}
              alt={alt}
            />
            {this.props.credit && (
              <span className="credit">© {this.props.credit.replace("©", "").replace("© ", "")}</span>
            )}
          </div>
        );
      }

      if (this.props.data.mobile && this.props.data.desktop) {
        return [
          <img
            key="mobile-image"
            className={`${this.props.className} mobile-image d-block d-md-none`}
            src={this.props.data.mobile.url}
            title={title}
            alt={alt}
          />,
          <img
            key="desktop-image"
            className={`${this.props.className} desktop-image d-none d-md-block`}
            src={this.props.data.desktop.url}
            title={title}
            alt={alt}
          />,
        ];
      }

      return (
        <div className="image-caption-wrapper">
          {this.props.data?.entity?.filemime === "image/svg+xml" && this.props.data?.entity?.uri ? (
            <img
              className={this.props.className}
              src={`${restHostBackend}/${this.props.data?.entity?.uri.replace("public://", "sites/default/files/")}`}
              title={title}
              alt={alt}
            />
          ) : (
            <>
              {this.props.style ? (
                <img
                  className={this.props.className}
                  src={this.props.style.url}
                  title={title}
                  alt={alt}
                />
              ) : (
                <img
                  className={this.props.className}
                  src={this.props.data.style.url}
                  title={title}
                  alt={alt}
                />
              )}
              {!this.props.data.style && this.props.data.mediaFileUrl?.path &&
                <img
                  className={this.props.className}
                  src={`${restHostBackend}/${this.props.data.mediaFileUrl.path}`}
                  title={title}
                  alt={alt}
                />
              }
            </>
          )}
          {(this.props.caption ||
              this.props.credit ||
              this.props.globalCaption) && (
              <div
                className={classNames({
                  "caption-wrap": true,
                  "col-16": this.props.wrapCaption,
                })}
              >
                {this.props.caption && (
                  <span className="caption">{this.props.caption}</span>
                )}
                {this.props.globalCaption && !this.props.caption && (
                  <span className="caption">{this.props.globalCaption}</span>
                )}
                {this.props.credit && (
                  <span className="credit">© {this.props.credit.replace("©", "").replace("© ", "")}</span>
                )}
              </div>
            )}
        </div>
      );
    }
  }
}

Image.propTypes = {
  /**
   * The image caption.
   */
  caption: PropTypes.string,
  /**
   * Used as fallback alt="" value.
   */
  nodeTitle: PropTypes.string,
  /**
   * The image credit.
   */
  credit: PropTypes.string,
  data: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    styleFullPage: PropTypes.shape({
      url: PropTypes.string,
    }),
    desktop: PropTypes.shape({
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  /**
   * Custom class names to add to the component.
   */
  className: PropTypes.string,
  /**
   * Only provided if used inside an image gallery component.
   */
  globalCaption: PropTypes.string,
  /**
   * In fullpage-mode, image and caption are swapped.
   */
  fullPage: PropTypes.bool,
  /**
   * Wrap caption?
   */
  wrapCaption: PropTypes.bool,
};

export default Image;
