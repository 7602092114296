import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";
import Appearances from "@nodes/shared-components/appearances/appearances";
import ColorMask from "@js/participants/components/color-mask";

const TeaserParticipantGeneralRelated = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const location = useLocation();

  const teaserClassNames = classNames({
    'node node-teaser masked related teaser-participant-general-related col-16 col-md-8': true,
    'with-image': item?.fieldImage,
    'without-image': !item?.fieldImage
  });

  const nameClassNames = classNames({
    'name': true,
    'headline-l': item?.title.length >= 6,
    'headline-xl': item?.title.length <= 6,
  });

  const infosClassNames = classNames({
    'infos': true,
    'blurred-background': !item?.fieldImage
  });

  return (
    <article
      className={teaserClassNames}
      data-index={index}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item?.id}
        destinationRoute={location.pathname}
      />
      <Link
        className="flex-wrap"
        to={item?.url?.path || item?.path.alias}
      >
        {item?.fieldImage &&
          <div className="mask-wrapper">
            <Image
              data={item?.fieldImage.fieldMediaImage}
              credit={item?.fieldImage.fieldCredits}
              nodeTitle={item?.title}
            />
            <ColorMask
              type={"teaser-participant"}
              forceMask={index === 0 ? 1 : 5}
            />
          </div>
        }

        <div className={infosClassNames}>
          <div className="title-wrapper">
            {item?.title &&
              <h4 className={nameClassNames}>
                {item?.title}
              </h4>
            }
            {item?.fieldSubline &&
              <h5 className="subline headline-s">
                {item?.fieldSubline}
              </h5>
            }
          </div>
          <div className="lower-part">
            {item?.fieldEventType &&
              <div className={`type body-m make-headline-light`}>
                {item.fieldEventType?.name}
              </div>
            }
            {item?.fieldAppearances &&
              <Appearances
                detailedMode={false}
                content={item}
                type={"event"}
                showAddToCalendar={false}
                viewMode={"teaser"}
                showEndDate={false}
                forceLongDayName={true}
              />
            }
          </div>
        </div>
      </Link>
    </article>
  );
};

export const TeaserParticipantGeneralRelatedPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserParticipantGeneralRelated.propTypes = {
  item: TeaserParticipantGeneralRelatedPropTypes
};

export default TeaserParticipantGeneralRelated;