// set default options for getMapsLink
const defaultOptions = {
  platform: "google",
};

/**
 * Returns a maps for a specified platform (google by default)
 * for given name, address, geo data and parent location.
 * @param {String} name
 * @param {Object} address
 * @param {Object} geo
 * @param {Object} options
 * @param {Object} parentLocation
 * @returns
 */
export const getMapsLink = (
  name,
  address = {},
  geo = {},
  options = defaultOptions,
  parentLocation = {}
) => {
  const mapsBaseUrl = "https://www.google.com/maps/?";

  // init empty url params for later use in different platforms
  let urlParams = {};

  const sanitizeNameForMaps = (name) => {
    let sanitizedName = name;

    // Remove whole name if location is situated on "Heiligengeistfeld", because Google cant find sub-asdresses here, because they simply do never exist.
    if (address?.addressLine1.includes("Heiligengeistfeld")) {
      sanitizedName = "";
    }

    // Remove sub-Locations from Locations that do not exist on maps (eg: Gender Equality Hub @ [...]). They are all using "@".
    if (name.includes("@")) {
      sanitizedName = name.replace(/.*@/, '');
    }

    // Remove sub-Locations from Locations that do not exist on maps (eg: Gender Equality Hub @ [...]). They are all using "@".
    if (parentLocation) {
      sanitizedName = name.replace(/\/.*/, '');
    }

    return sanitizedName;
  }

  // set name
  urlParams.name = sanitizeNameForMaps(name, address);

  // format address if address was given
  let addr = [];
  address.addressLine1 && addr.push(address.addressLine1);
  address.addressLine2 && addr.push(address.addressLine2);
  address.postalCode && addr.push(address.postalCode);
  address.locality && addr.push(address.locality);
  address.countryCode && addr.push(address.countryCode);
  if (!!addr.length) {
    urlParams.address = encodeURIComponent(addr.join(", "));
  }

  // set lat-lng data
  if (geo.lat && geo.lng) {
    urlParams.ll = `${geo.lat},${geo.lng}`;
  }

  switch (options.platform) {
    case "apple": {
      const urlParamsArray = ["maps://www.google.com/maps/?"];
      urlParams.address
        ? urlParamsArray.push(`q=${urlParams.name}%2C%20${urlParams.address}`)
        : urlParamsArray.push(`q=${urlParams.name}`);
      urlParams.ll && urlParamsArray.push(`ll=${urlParams.ll}`);
      const formattedUrl = urlParamsArray.join("&");
      return formattedUrl;
    }
    case "android": {
      const urlParamsArray = ["geo://www.google.com/maps/?"];
      urlParams.address
        ? urlParamsArray.push(`q=${urlParams.name}%2C%20${urlParams.address}`)
        : urlParamsArray.push(`q=${urlParams.name}`);
      urlParams.ll && urlParamsArray.push(`ll=${urlParams.ll}`);
      const formattedUrl = urlParamsArray.join("&");
      return formattedUrl;
    }
    case "web":
    default: {
      const urlParamsArray = ["https://www.google.com/maps/?"];
      urlParams.address
        ? urlParamsArray.push(`q=${urlParams.name}%2C%20${urlParams.address}`)
        : urlParamsArray.push(`q=${urlParams.name}`);
      urlParams.ll && urlParamsArray.push(`ll=${urlParams.ll}`);
      const formattedUrl = urlParamsArray.join("&");
      return formattedUrl;
    }
  }
};
