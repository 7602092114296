import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { useIntl } from "react-intl";

const StringFilter = ({ item }) => {
  const intl = useIntl();

  return (
    <Field
      id={item.options.expose.identifier}
      name="combine"
      type="text"
      placeholder={intl.formatMessage({ id: item.options.expose.label })}
    />
  );
};

StringFilter.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.shape({
      id: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        identifier: PropTypes.string,
      }),
    }),
  }),
};

export default StringFilter;
