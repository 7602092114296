import React from "react";

const BusinessWrapper = ({content}) => {
  if (content.fieldCompany || content.fieldPosition) {
    return (
      <div className="business-wrapper text body-m">
        <p>
          {content.fieldCompany &&
            <>
              <span
                className="company body-m"
                dangerouslySetInnerHTML={{ __html: content.fieldCompany }}
              />{" "}
            </>
          }
          {content.fieldPosition && (
            <div className="position body-m">
               {content.fieldCompany &&
                  <>
                    /
                  </>
               }
               <span
                 dangerouslySetInnerHTML={{ __html: content.fieldPosition }}
               />

            </div>
          )}
        </p>
      </div>
    );
  } else {
    return false;
  }
};

export default BusinessWrapper;
