import React from "react";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { FieldArray, useFormikContext } from "formik";
import classNames from "classnames";

const ListFilter = ({ item }) => {
  const { values } = useFormikContext();

  /**
   * Query the field storage config to get the list fields options.
   * Access is restricted and handled in graphql_schema_extension.
   * Add it there, otherwise it will return null.
   */
  const FIELD_STORAGE_CONFIG_QUERY = gql`
    query QueryFieldStorageConfig($id: String!) {
      entityQuery(
        entityType: FIELD_STORAGE_CONFIG
        filter: {
          conditions: { field: "id", value: [$id], operator: CONTAINS }
        }
      ) {
        total
        items {
          label
          toArray
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(FIELD_STORAGE_CONFIG_QUERY, {
    variables: {
      id: item.options.id.replace("_value", ""),
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-8 offset-md-1">
            <h3>
              <FormattedMessage id="error.general" />
            </h3>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  }

  const options =
    !!data.entityQuery.items.length &&
    !!data.entityQuery.items[0] &&
    Object.entries(data.entityQuery.items[0]?.toArray.settings.allowed_values);


  const fieldIdentifier = item.options.expose.identifier;

  if (!options) return null;
  return (
    <FieldArray
      name={fieldIdentifier}
      render={(arrayHelpers) => (
        <div>
          {options.map((option) => (
            <label
              key={option[0]}
              className={classNames({
                active: values[fieldIdentifier]?.includes(option[0]),
                "selectable": true
              })}
            >
              <input
                name={fieldIdentifier}
                type="checkbox"
                value={option[0]}
                checked={values[fieldIdentifier]?.includes(option[0])}
                onChange={(e) => {
                  if (e.target.checked) {
                    arrayHelpers.push(option[0]);
                  } else {
                    const idx = values[fieldIdentifier].indexOf(option[0]);
                    arrayHelpers.remove(idx);
                  }
                }}
              />
              <span>{option[1]}</span>
            </label>
          ))}
        </div>
      )}
    />
  );
};

ListFilter.propTypes = {
  item: PropTypes.shape({
    options: PropTypes.shape({
      id: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        identifier: PropTypes.string,
      }),
    }),
  }),
};

export default ListFilter;
