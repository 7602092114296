import React from "react";
import PropTypes from "prop-types";
import moment from 'moment-timezone';
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
const FieldTime = ({ date, timestamp, format, showSuffix, dependsOnReleaseState }) => {
  const dateValue = timestamp
    ? date
    : date.replace(" UTC", "").trim().replace(" ", "T");

  return (
    <time
      dateTime={
        timestamp
          ? moment.unix(dateValue).format("YYYY-MM-DD")
          : dateValue.replace(" UTC", "Z").replace(" ", "T")
      }
    >
        <span
          className={classNames({
            "d-flex": true,
          })}
        >
          {timestamp ? (
            <>{moment.unix(dateValue).format(format)}</>
          ) : (
            <>{moment(dateValue).format(format)}</>
          )}
          {showSuffix &&
            <>
              {" "}
              <FormattedMessage id={"time.suffix"}/>
            </>
          }
        </span>
      {/*this.props.mobileFormat && (
          <span className="d-flex d-md-none">
            {timestamp ? (
              <>{moment.unix(dateValue).format(this.props.mobileFormat)}</>
            ) : (
              <>{moment(dateValue).format(this.props.mobileFormat)}</>
            )}
            {showSuffix &&
              <FormattedMessage id={"time.suffix"}/>
            }
          </span>
        )*/}
    </time>
  );
}

FieldTime.propTypes = {
  /**
   * Use dateField.value as date prop.
   */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * The format used by moment.format().
   */
  format: PropTypes.string.isRequired,
  /**
   * Optional format for mobile display only.
   */
  mobileFormat: PropTypes.string,
  /**
   * Interpret date as timestamp or date string?
   */
  timestamp: PropTypes.bool,
  /**
   * Show "o' Clock" or something different after the time.
   */
  showSuffix: PropTypes.bool,
};

export default FieldTime;
